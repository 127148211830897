import { Box, Button, Dialog, TextArea } from '@sefar/design-system'
import React, { useState } from 'react'
import { useTranslate } from '../../../../hooks/useTranslate'

export function NoteDialog({
  open,
  setOpen,
  onFeedbackSubmit,
  initialMessage = ''
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onFeedbackSubmit: (feedBackMessage: string) => void
  initialMessage?: string
}) {
  const { t } = useTranslate()
  const [note, setNote] = useState<string>(initialMessage)

  return (
    <Dialog
      onOpenChange={() => {
        setNote(initialMessage)
      }}
      setOpenControlledDialog={setOpen}
      open={open}
      title={t('field_purchase_requisition_note')}
      contentProps={{ css: { '@lg': { width: 550 } } }}
      footer={
        <Box css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={!note}
            onClick={() => onFeedbackSubmit(note)}
            variant="primary"
          >
            {t('field_purchase_requisition_note')}
          </Button>
        </Box>
      }
    >
      <TextArea
        value={note}
        id="note"
        name="note"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setNote(e.target.value)
        }
        css={{ minHeight: '200px' }}
      />
    </Dialog>
  )
}
