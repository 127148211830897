import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Body6,
  Box,
  Heading5,
  Space,
  Spinner,
  TextLink
} from '@sefar/design-system'
import { SpaceArticleHomePreview } from './home'
import {
  patchUserSpacesBookmarks,
  SPACE_ARTICLE_TYPE,
  SpaceArticlePreview,
  SpacesBookmarkSimple,
  useMe,
  useSpaceArticlesPreview
} from '../../api'
import { useTranslate } from '../../hooks/useTranslate'
import { useConfigStore } from '../../state/config'

export const HomeSpaceArticlesPreview = () => {
  const { t } = useTranslate()
  const { spaceArticles, isLoading } = useSpaceArticlesPreview('-published_at')
  const { id: userId } = useConfigStore()
  const { me, mutate: mutateUser } = useMe(userId)
  const [bookmarks, setBookmarks] = useState<SpacesBookmarkSimple[]>([])

  useEffect(() => {
    me?.spacesBookmarks && setBookmarks([...me?.spacesBookmarks])
  }, [me?.id])

  const getIsSpaceArticleBookmarked = (article: SpaceArticlePreview) =>
    !!bookmarks.find((item) => item?.id === article.id)

  const onFavouriteClick = async (spaceArticle: SpaceArticlePreview) => {
    let bookmarksUpdated: SpacesBookmarkSimple[]
    const isBookmarked = getIsSpaceArticleBookmarked(spaceArticle)
    if (isBookmarked) {
      bookmarksUpdated = [
        ...bookmarks.filter(
          (item: SpacesBookmarkSimple) => item.id !== spaceArticle.id
        )
      ]
    } else {
      bookmarksUpdated = [
        ...bookmarks,
        { id: spaceArticle.id, type: SPACE_ARTICLE_TYPE }
      ]
    }

    setBookmarks([...bookmarksUpdated])
    await patchUserSpacesBookmarks(me?.id, [...bookmarksUpdated])
    mutateUser()
  }

  return (
    <Box css={{ px: '$3' }}>
      <Space
        mb="SM"
        css={{
          d: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Heading5>{t('field_spaces_articles')}</Heading5>
        <Body6>
          <TextLink
            as={Link}
            to={'/spaces'}
            variant="inline"
            css={{
              color: '$neutralLighten80',
              '&:hover': {
                color: '$white',
                borderBottomColor: '$white'
              }
            }}
          >
            {t('field_see_all')}
          </TextLink>
        </Body6>
      </Space>

      {isLoading ? (
        <Spinner />
      ) : (
        spaceArticles.map((spaceArticle) => (
          <SpaceArticleHomePreview
            key={spaceArticle.id}
            article={spaceArticle}
            isBookmarked={getIsSpaceArticleBookmarked(spaceArticle)}
            onFavouriteClick={() => onFavouriteClick(spaceArticle)}
          />
        ))
      )}
    </Box>
  )
}
