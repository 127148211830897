import React from 'react'
import { styled } from '../../../../../stitches.config'
import {
  Box,
  Button,
  ContainerPadding,
  HelpIcon20,
  Grid
} from '@sefar/design-system'
import { bgImageMetaDataType } from '../app'
import { useTranslate } from '../hooks/useTranslate'

const FooterStyled = styled('div', {
  d: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  fontSize: '$body6',
  lineHeight: '$16',
  fontWeight: '$normal',
  '@sm': {
    py: '$4',
    fontSize: '$body7',
    lineHeight: '$13',
    height: '$footerHeightSm'
  },
  '@md': {
    py: '$6',
    height: '$footerHeightMd'
  },
  variants: {
    variant: {
      dark: {
        color: '$neutralLighten60',
        backgroundColor: 'transparent'
      },
      light: {
        color: '$neutralLighten50',
        borderTop: '1px $neutralLighten90 solid',
        backgroundColor: 'transparent'
      }
    }
  },
  defaultVariants: {
    variant: 'light'
  }
})

const CodeOfConductLink = styled('a', {
  transition: '$default',
  cursor: 'pointer',
  d: 'inline-flex',
  alignItems: 'center',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  fontWeight: '$normal',
  textDecoration: 'none',
  height: 20,
  alignSelf: 'center',
  '@md': {
    height: 25
  },

  '&:hover, &.__hover': {
    color: '$primaryBlueDarken30',
    borderBottom: '1px solid $primaryBlueDarken30'
  },
  variants: {
    variant: {
      dark: {
        color: '$neutralLighten60',
        borderBottom: '1px $neutralLighten60 solid'
      },
      light: {
        color: '$neutralLighten50',
        borderBottom: '1px $neutralLighten50 solid'
      }
    }
  },
  defaultVariants: {
    variant: 'light'
  }
})

type FooterPropsTypes = {
  variant: 'dark' | 'light'
  bgImageMeta: bgImageMetaDataType | null
  isHomePage: boolean
}

export const Footer = ({
  variant,
  bgImageMeta,
  isHomePage
}: FooterPropsTypes) => {
  const { t } = useTranslate()

  return (
    <FooterStyled variant={variant}>
      <ContainerPadding css={{ flex: 1 }}>
        <Grid
          css={{
            position: 'relative'
          }}
        >
          <Box
            css={{
              gc: '1 / span 4',
              gcSm: '1 / span 12',
              d: 'flex',
              gap: '$2',
              height: 34,
              '@sm': {
                order: 2,
                justifyContent: 'center'
              }
            }}
          >
            <Box
              css={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {t('field_sefar_group', [new Date().getFullYear().toString()])}
            </Box>
            <CodeOfConductLink href="https://www.sefar.com/" target="_blank">
              {t('field_code_of_conduct')}
            </CodeOfConductLink>
          </Box>
          {bgImageMeta && (
            <Box
              css={{
                gc: '5 / span 4',
                gcSm: '1 / span 12',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '@sm': {
                  order: 1
                }
              }}
            >
              {isHomePage && (
                <Box as="span">
                  {bgImageMeta.location && <span>{bgImageMeta.location}</span>}
                  {bgImageMeta.photographer && (
                    <span>, by {bgImageMeta.photographer}</span>
                  )}
                </Box>
              )}
            </Box>
          )}
          <Box
            css={{
              gc: '9 / span 4',
              gcSm: '11 / span 12',
              d: 'flex',
              justifyContent: 'flex-end',

              '@sm': {
                order: 3,
                position: 'absolute',
                right: 0,
                bottom: 0
              }
            }}
          >
            <Button
              as="a"
              variant={`secondary${variant === 'dark' ? '-inverted' : ''}`}
              href="https://form.typeform.com/to/KgwXjc8c"
              target="_blank"
              css={{
                size: 34,
                padding: 0
              }}
            >
              <Box as="span" className="icon">
                <HelpIcon20 />
              </Box>
            </Button>
          </Box>
        </Grid>
      </ContainerPadding>
    </FooterStyled>
  )
}
