import { Box, Heading1 } from '@sefar/design-system'
import { OrderDetailsTable } from '../components/order-details-table'
import { OrderItemsTable } from '../components/order-items-table'
import { useOrderState } from '../hooks/use-order-state'
import { useTranslate } from '../../../../hooks/useTranslate'
import { OrderCommentsHistory } from '../components/order-comments-history'
import { OrderLNCommentsHistory } from '../components/order-ln-comments'

export const OverviewStep = () => {
  const { t } = useTranslate()
  const { order } = useOrderState()

  return (
    <Box css={{ w: '100%', px: '$8' }}>
      <Box css={{ mb: '$15', d: 'flex', flexDirection: 'column', gap: '$15' }}>
        <Heading1 css={{ textAlign: 'center' }}>
          {t('field_purchase_order_overview_title')}
        </Heading1>
        <OrderCommentsHistory order={order} />
        <OrderItemsTable orderItems={order.purchaseRequisitionItems} />
        <OrderDetailsTable orderDetails={order} />
        <OrderLNCommentsHistory order={order} />
      </Box>
    </Box>
  )
}
