import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CloseIcon20 } from '@sefar/design-system'
import { CloseConfirmationDialog } from './close-confirmation-dialog'
import { purchaseRequisitionsPageUrl } from '../../../../app'

export const Heading = ({
  noConfirmation = false
}: {
  noConfirmation?: boolean
}) => {
  const navigate = useNavigate()

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const onCloseButtonClick = () => {
    if (noConfirmation) {
      navigate(`/${purchaseRequisitionsPageUrl}`)
    } else {
      setIsConfirmationOpen(true)
    }
  }

  return (
    <Box css={{ h: '5rem', zIndex: 3 }}>
      <Box
        css={{
          position: 'fixed',
          top: '$8',
          right: '$8'
        }}
      >
        <Box
          onClick={onCloseButtonClick}
          css={{
            d: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '3rem',
            height: '3rem',
            borderRadius: '$2',
            border: '1px solid $neutralLighten80',
            cursor: 'pointer',
            color: 'inherit',
            backgroundColor: '$white'
          }}
        >
          <CloseIcon20 />
        </Box>
      </Box>
      <CloseConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </Box>
  )
}
