import { Box, Checkbox } from '@sefar/design-system'
import remove from 'lodash/remove'

type CheckboxGroupProps = {
  options: {
    label: string
    value: string
  }[]
  value?: string[]
  onChange: (v: string[]) => void
}

export const CheckboxGroup = ({
  options = [],
  onChange,
  value = []
}: CheckboxGroupProps) => {
  return (
    <Box>
      {options.map((opt) => (
        <Box css={{ my: '$3', cursor: 'pointer' }} key={opt.value}>
          <Checkbox
            label={opt.label}
            value={opt.value}
            checked={value?.includes(opt.value)}
            onCheckedChange={(checked) => {
              const updatedSelection = [...value]
              if (checked) {
                updatedSelection.push(opt.value)
              } else {
                remove(updatedSelection, (val) => val === opt.value)
              }
              onChange(updatedSelection)
            }}
          />
        </Box>
      ))}
    </Box>
  )
}
