import React, { useEffect, useState } from 'react'
import { Box, Label } from '../utils'
import { Space } from '../space'
import { CalendarIcon20, TimeIcon20 } from '../icons'
import dateFns from 'date-and-time'
import { styled } from '../../../stitches.config'
import Picker from 'rc-picker'
import locale from 'date-fns/locale/en-US'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import enUS from 'rc-picker/lib/locale/en_US'
import './style.css'

locale.options.weekStartsOn = 1

function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

const IconBox = styled(Box, {
  position: 'absolute',
  right: 0,
  top: 0,
  pointerEvents: 'none',
  height: '100%',
  d: 'flex',
  alignItems: 'center'
})

const StyledInput = styled('input', { width: '100%' })

export function isValidDate(date: Date) {
  return !isNaN(date?.getTime())
}

export const StyledPicker = styled(Picker, {})

export function DateTimePicker({
  id,
  initialValue,
  max,
  min,
  disabled = false,
  onChange,
  dateOnlyMode = false,
  autoFocus = true,
  value,
  inputStyle = {}
}: {
  id: string
  initialValue?: Date
  value?: Date
  disabled?: boolean
  max?: Date
  min?: Date
  onChange?: (date: Date | undefined) => void
  dateOnlyMode?: boolean
  autoFocus?: boolean
  inputStyle?: React.CSSProperties
}) {
  const onPickerChange = (newValue: Date | null, formatString?: string) => {
    onChange && onChange(disabled ? undefined : newValue)
  }
  const sharedProps = {
    generateConfig: dateFnsGenerateConfig,
    onChange: onPickerChange
  }

  const isMobile = detectMob()
  const [date, setDate] = useState<string>(
    (initialValue && dateFns.format(initialValue, 'YYYY-MM-DD')) || ''
  )
  const [time, setTime] = useState<string>(
    (initialValue && dateFns.format(initialValue, 'HH:mm')) || '00:00'
  )

  useEffect(() => {
    const fullDate = new Date(`${date}T${time}`)
    onChange &&
      onChange(
        disabled ? undefined : isValidDate(fullDate) ? fullDate : undefined
      )
  }, [date, time, disabled])

  return isMobile ? (
    <Box
      css={{
        d: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        transition: '$default',
        border: '1px $neutralLighten80 solid',
        borderRadius: '$4',
        boxShadow: '$default',
        color: '$neutral',
        backgroundColor: disabled ? '$neutralLighten97' : '$white',
        '& .icon-box': {
          backgroundColor: disabled ? '$neutralLighten97' : '$white'
        },
        '&:hover, &:focus-within, &.__hover, &.__focus': {
          borderColor: '$neutralLighten60',
          backgroundColor: '$neutralLighten97',
          '& div': {
            color: '$neutral'
          },

          '& .icon-box': {
            backgroundColor: '$neutralLighten97'
          }
        },
        '&:focus-within, &.__focus': {
          outline: '1px $primaryBlue solid',
          borderColor: '$primaryBlue',
          backgroundColor: '$neutralLighten97',

          '& .icon-box': {
            backgroundColor: '$neutralLighten97'
          }
        },

        '& input': {
          textAlign: 'left',
          px: '$4',
          fontSize: '$body5Xl',
          lineHeight: '$15',
          py: '$2',
          color: 'inherit',
          '&::placeholder': {
            color: '$neutralLighten50'
          },
          font: 'inherit',
          appearance: 'none',
          outline: 'none',
          border: '0',
          backgroundColor: 'transparent',

          '&[type=date]': {
            minWidth: 160,
            pr: '0',
            borderRightWidth: '0'
          },

          '&[type=time]': {
            minWidth: 104,
            pl: '0',
            pr: '$2',
            borderLeftWidth: '0'
          }
        }
      }}
    >
      <Box css={{ position: 'relative', width: '100%' }}>
        <StyledInput
          type="date"
          id={`date-${id}`}
          value={disabled ? '' : date}
          max={
            max &&
            dateFns.format(
              min && dateFns.subtract(max, min).toSeconds() < 0 ? min : max,
              'YYYY-MM-DD'
            )
          }
          min={min && dateFns.format(min, 'YYYY-MM-DD')}
          onChange={({ currentTarget }) => setDate(currentTarget.value)}
          disabled={disabled}
        />
        <IconBox className="icon-box">
          <CalendarIcon20 />
        </IconBox>
      </Box>

      <Space
        mx="3XS"
        css={{
          height: 40,
          borderRight: dateOnlyMode ? '' : '1px solid $neutralLighten80'
        }}
      />

      {!dateOnlyMode && (
        <Box css={{ position: 'relative', width: '100%' }}>
          <StyledInput
            type="time"
            id={`time-${id}`}
            value={time}
            onChange={({ currentTarget }) => setTime(currentTarget.value)}
            disabled={disabled}
          />
          <IconBox
            className="icon-box"
            css={{
              right: 4,
              '@md': {
                right: 8
              }
            }}
          >
            <TimeIcon20 />
          </IconBox>
        </Box>
      )}
    </Box>
  ) : (
    <StyledPicker
      {...sharedProps}
      locale={enUS}
      placeholder={dateOnlyMode ? 'DD.MM.YYYY' : undefined}
      defaultValue={initialValue}
      showTime={dateOnlyMode ? undefined : { format: 'HH:mm' }}
      format={dateOnlyMode ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm'}
      autoFocus={autoFocus}
      value={value}
      popupStyle={{
        pointerEvents: 'auto'
      }}
      style={inputStyle}
      suffixIcon={<CalendarIcon20 />}
      disabledDate={(current) => {
        if (current) {
          const isInMax = max ? current.valueOf() < max.valueOf() : true
          const isInMin = min ? current.valueOf() > min.valueOf() : true
          return !isInMax || !isInMin
        }
      }}
    />
  )
}

export type DateRangeDateOutput = {
  start: Date | undefined
  end: Date | undefined
}
export function DateRange({
  publicationDates,
  initialStart,
  initialEnd,
  endDateEnabled = false,
  startDateEnabled = true,
  onChange
}: {
  publicationDates: DateRangeDateOutput
  initialStart?: Date | undefined
  initialEnd?: Date | undefined
  endDateEnabled?: boolean
  startDateEnabled?: boolean
  onChange?: (data: DateRangeDateOutput) => void
}) {
  const [start, setStart] = useState<Date | undefined>(initialStart)
  const [end, setEnd] = useState<Date | undefined>(initialEnd)

  useEffect(() => {
    onChange && onChange({ start, end })
  }, [start, end])

  return (
    <Box css={{ d: 'flex', flexWrap: 'wrap', gap: '$4' }}>
      {startDateEnabled && (
        <Box css={{ width: '100%', '@md': { width: 'auto' } }}>
          <Label htmlFor="date-start" css={{ d: 'block', pl: 0, mb: '$2' }}>
            Publication date
          </Label>
          <DateTimePicker
            id={'start'}
            initialValue={publicationDates.start}
            onChange={setStart}
            min={startDateEnabled ? new Date() : undefined}
            max={end && dateFns.addDays(end, -1)}
          />
        </Box>
      )}
      <Box
        css={{
          opacity: endDateEnabled ? 1 : 0,
          width: '100%',
          '@md': { width: 'auto' },
          pointerEvents: endDateEnabled ? 'auto' : 'none'
        }}
      >
        <Label
          htmlFor="date-end"
          css={{ d: 'block', pl: 0, mb: '$2' }}
          data-disabled={!endDateEnabled}
        >
          Expiration date (unpublish)
        </Label>
        <DateTimePicker
          id={'end'}
          initialValue={publicationDates.end}
          onChange={setEnd}
          min={(start && dateFns.addDays(start, 1)) || new Date()}
        />
      </Box>
    </Box>
  )
}
