import {
  Body4,
  Body6,
  Box,
  Container,
  Grid,
  SearchIcon20,
  ClockIcon14,
  SearchNoResultsIcon48,
  Space,
  Spinner,
  Tabs,
  TabsContent,
  TextLink
} from '@sefar/design-system'
import {
  ArticleLinkHoverStyles,
  ArticleSearchResultCardSmall,
  PeopleSearchResultCard,
  SearchEntityTypesLabels,
  SearchInput,
  SearchTabList,
  SubSpaceSearchResultCardSmall,
  useSearchLogic
} from '../search/search-utils'
import {
  NewsArticle,
  SpaceArticle,
  SearchEntityTypes,
  User,
  SubSpaceSimple
} from '../../api'
import { createSearchParams, Link } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { useClickAway, useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES } from '../../../../../../stitches.config'
import { newsPageUrl, spacesPageUrl, subSpacePageUrl } from '../../app'
import { useTranslate } from '../../hooks/useTranslate'
import { useConfigStore } from '../../state/config'

const resultArticleLinkStyles = {
  px: '$6',
  '&:hover': {
    ...ArticleLinkHoverStyles,
    ml: 0,
    px: '$6'
  }
}
export function HomeSearch({
  focused,
  setFocused
}: {
  focused: boolean
  setFocused: (focused: boolean) => void
}) {
  const {
    news,
    meta,
    isLoading,
    people,
    spaces,
    subSpaces,
    totalCount,
    searchParamsUi,
    changeSearchParamsUi,
    changeType
  } = useSearchLogic()
  const { me } = useConfigStore()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const { t } = useTranslate()
  const onFocus = () => {
    if (isSm) {
      document.body.style.overflowY = 'hidden'
    }
    setFocused(true)
  }
  const ref = useRef(null)
  useClickAway(ref, () => {
    setFocused(false)
    document.body.style.overflowY = 'initial'
  })

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'initial'
    }
  }, [])

  return (
    <Container
      css={{
        '@sm': focused
          ? {
              width: '100%'
            }
          : {}
      }}
    >
      <Grid>
        <Tabs
          value={searchParamsUi.type as string}
          onValueChange={changeType as (type: string) => void}
          css={{
            gc: '1 / span 12',
            gcLg: '2 / span 10'
          }}
        >
          <Space
            ref={ref}
            mb="MD"
            css={{
              mt: isSm && focused ? 0 : '$2',
              backgroundColor: focused ? 'white' : 'transparent',
              borderRadius: isSm ? 0 : 26,
              pt: '$8',
              d: 'flex',
              flexDirection: 'column',
              '@sm': focused
                ? {
                    height: 'calc(var(--app-height) - 155px)',
                    maxHeight: 'calc(var(--app-height) - 155px)',
                    pt: '$4'
                  }
                : { height: '100%', pt: '$4' },
              '@md': {
                height: '100%',
                maxHeight: 'calc(var(--app-height) - 380px)',
                mt: '$8',
                pt: 0
              }
            }}
          >
            <Space css={{ d: 'flex', px: '$4' }}>
              <SearchInput
                value={searchParamsUi.search}
                onChange={(value: string) => {
                  changeSearchParamsUi({ search: value, page: 0 })
                }}
                disabled={isLoading}
                onFocus={onFocus}
                onCancel={isSm && focused ? () => setFocused(false) : null}
              />
            </Space>

            {focused && !searchParamsUi.search ? (
              <Space
                mt="SM"
                pb="3XS"
                css={{
                  px: '$6',
                  d: 'flex',
                  flexDirection: 'column-reverse'
                }}
              >
                {me?.recentSearchResults?.length
                  ? me?.recentSearchResults.map((result, index) => (
                      <Box
                        css={{
                          d: 'flex',
                          alignItems: 'center',
                          px: '$2',
                          py: '$5',
                          borderBottom:
                            index !== 0
                              ? '1px solid $neutralLighten90'
                              : '1px solid transparent',
                          transition: 'opacity .3s',
                          opacity: 1,
                          cursor: 'pointer',
                          '&:hover': {
                            opacity: 0.5
                          }
                        }}
                        onClick={() => {
                          changeSearchParamsUi({ search: result, page: 0 })
                        }}
                      >
                        <ClockIcon14
                          width={24}
                          height={24}
                          style={{ color: '#00192A' }}
                        />
                        <Body4 fontWeight="bold" css={{ ml: '$4' }}>
                          {result}
                        </Body4>
                      </Box>
                    ))
                  : null}
              </Space>
            ) : null}
            {focused && searchParamsUi.search && (
              <SearchTabList
                meta={meta}
                totalCount={totalCount}
                css={{
                  borderBottom: '1px $neutralLighten90 solid',
                  px: '$6'
                }}
              />
            )}

            {focused &&
              searchParamsUi.search &&
              (totalCount ? (
                <>
                  <Box
                    css={{
                      d: 'flex',
                      flexDirection: 'column',
                      maxHeight: '100%',
                      overflowY: 'auto',
                      overflowX: 'hidden'
                    }}
                  >
                    <TabsContent
                      value={SearchEntityTypes.All}
                      data-cy="SearchEntityTypesAll"
                    >
                      {people?.length ? (
                        <>
                          <Space
                            mt="SM"
                            pb="3XS"
                            css={{
                              borderBottom: '1px $neutralLighten90 solid',
                              px: '$6'
                            }}
                          >
                            <Body6>
                              {
                                SearchEntityTypesLabels[
                                  SearchEntityTypes.People
                                ]
                              }
                            </Body6>
                          </Space>
                          {people.slice(0, 3).map((user: User) => (
                            <PeopleSearchResultCard
                              user={user}
                              small={true}
                              key={user.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                        </>
                      ) : (
                        <></>
                      )}

                      {news?.length ? (
                        <>
                          <Space
                            mt="SM"
                            pb="3XS"
                            css={{
                              borderBottom: '1px $neutralLighten90 solid',
                              px: '$6'
                            }}
                          >
                            <Body6>
                              {SearchEntityTypesLabels[SearchEntityTypes.News]}
                            </Body6>
                          </Space>
                          {news.slice(0, 10).map((article: NewsArticle) => (
                            <ArticleSearchResultCardSmall
                              article={article}
                              link={`/${newsPageUrl}/${article.id}`}
                              key={article.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                        </>
                      ) : (
                        <></>
                      )}

                      {subSpaces?.length ? (
                        <>
                          <Space
                            mt="SM"
                            pb="3XS"
                            css={{
                              borderBottom: '1px $neutralLighten90 solid',
                              px: '$6'
                            }}
                          >
                            <Body6>
                              {
                                SearchEntityTypesLabels[
                                  SearchEntityTypes.SubSpaces
                                ]
                              }
                            </Body6>
                          </Space>
                          {subSpaces
                            .slice(0, 10)
                            .map((subSpace: SubSpaceSimple) => (
                              <SubSpaceSearchResultCardSmall
                                subSpace={subSpace}
                                link={`/${subSpacePageUrl}/${subSpace.spaceId}/${subSpace.id}`}
                                key={subSpace.id}
                                css={resultArticleLinkStyles}
                              />
                            ))}
                        </>
                      ) : (
                        <></>
                      )}

                      {spaces?.length ? (
                        <>
                          <Space
                            mt="SM"
                            pb="3XS"
                            css={{
                              borderBottom: '1px $neutralLighten90 solid',
                              px: '$6'
                            }}
                          >
                            <Body6>
                              {
                                SearchEntityTypesLabels[
                                  SearchEntityTypes.Spaces
                                ]
                              }
                            </Body6>
                          </Space>
                          {spaces.slice(0, 10).map((article: SpaceArticle) => (
                            <ArticleSearchResultCardSmall
                              article={article}
                              link={`/${spacesPageUrl}/${article.id}`}
                              key={article.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </TabsContent>

                    <TabsContent
                      value={SearchEntityTypes.People}
                      data-cy="SearchEntityTypesPeople"
                    >
                      {people &&
                        people
                          .slice(0, 3)
                          .map((user: User) => (
                            <PeopleSearchResultCard
                              user={user}
                              small={true}
                              key={user.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                    </TabsContent>

                    <TabsContent
                      value={SearchEntityTypes.News}
                      data-cy="SearchEntityTypesNews"
                    >
                      {news &&
                        news
                          .slice(0, 10)
                          .map((article: NewsArticle) => (
                            <ArticleSearchResultCardSmall
                              article={article}
                              link={`/${newsPageUrl}/${article.id}`}
                              key={article.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                    </TabsContent>

                    <TabsContent value={SearchEntityTypes.Spaces}>
                      <Space
                        mt="SM"
                        pb="3XS"
                        css={{
                          borderBottom: '1px $neutralLighten90 solid',
                          px: '$6'
                        }}
                      >
                        <Body6>
                          {SearchEntityTypesLabels[SearchEntityTypes.SubSpaces]}
                        </Body6>
                      </Space>
                      {subSpaces &&
                        subSpaces
                          .slice(0, 10)
                          .map((subSpace: SubSpaceSimple) => (
                            <SubSpaceSearchResultCardSmall
                              subSpace={subSpace}
                              link={`/${subSpacePageUrl}/${subSpace.spaceId}/${subSpace.id}`}
                              key={subSpace.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                      <Space
                        mt="SM"
                        pb="3XS"
                        css={{
                          borderBottom: '1px $neutralLighten90 solid',
                          px: '$6'
                        }}
                      >
                        <Body6>
                          {SearchEntityTypesLabels[SearchEntityTypes.Spaces]}
                        </Body6>
                      </Space>
                      {spaces &&
                        spaces
                          .slice(0, 10)
                          .map((article: SpaceArticle) => (
                            <ArticleSearchResultCardSmall
                              article={article}
                              link={`/${spacesPageUrl}/${article.id}`}
                              key={article.id}
                              css={resultArticleLinkStyles}
                            />
                          ))}
                    </TabsContent>
                  </Box>
                  <Space
                    py="XS"
                    css={{
                      px: '$6',
                      borderTop: '1px $neutralLighten90 solid'
                    }}
                  >
                    <TextLink
                      as={Link}
                      to={`/search?${createSearchParams(
                        searchParamsUi as Record<string, string>
                      )}`}
                      data-cy="showAllResultsButton"
                    >
                      <Box
                        as="span"
                        css={{ mr: '$6' }}
                        className="icon icon-right"
                      >
                        <SearchIcon20 />
                      </Box>
                      <span>
                        {t('field_show_all_results')} ({totalCount})
                      </span>
                    </TextLink>
                  </Space>
                </>
              ) : (
                <Space
                  my="3XL"
                  css={{
                    d: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '$neutral'
                  }}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <Space mb="SM">
                        <SearchNoResultsIcon48 />
                      </Space>
                      <Body4>{t('field_no_results_found')}</Body4>
                    </>
                  )}
                </Space>
              ))}
          </Space>
        </Tabs>
      </Grid>
    </Container>
  )
}
