import {
  Body6,
  Box,
  Heading5,
  Space,
  Spinner,
  TextLink
} from '@sefar/design-system'
import { Link } from 'react-router-dom'
import { ChannelTypeEnum, NewsArticle, useNews } from '../../api'
import React from 'react'
import { NewsArticleHomePreview } from './home'
import { useTranslate } from '../../hooks/useTranslate'
import { localStorageKeys, sessionStorageKeys } from '../../constants'

export function HomeNewsPreview() {
  const { t } = useTranslate()
  const { news: newsPreview } = useNews(
    sessionStorage.getItem(sessionStorageKeys.NEWS_CHOSEN_REGION) ===
      ChannelTypeEnum.all
      ? ChannelTypeEnum.all
      : sessionStorage.getItem(sessionStorageKeys.NEWS_CHOSEN_REGION) ||
          ChannelTypeEnum.all,
    localStorage.getItem(localStorageKeys.NEWS_CHOSEN_COUNTRY) || '',
    3
  )

  return (
    <Box css={{ px: '$3' }}>
      <Space
        mb="SM"
        css={{
          d: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Heading5>{t('field_news')}</Heading5>
        <Body6>
          <TextLink
            as={Link}
            to={'/news'}
            variant="inline"
            css={{
              color: '$neutralLighten80',
              '&:hover': {
                color: '$white',
                borderBottomColor: '$white'
              }
            }}
          >
            {t('field_see_all')}
          </TextLink>
        </Body6>
      </Space>

      {newsPreview?.length ? (
        newsPreview
          .slice(0, 3)
          .map((article: NewsArticle) => (
            <NewsArticleHomePreview key={article.id} article={article} />
          ))
      ) : (
        <Spinner />
      )}
    </Box>
  )
}
