import {
  Body1,
  Body5,
  Body6,
  Box,
  Container,
  ContainerPadding,
  Grid,
  Space,
  Carousel,
  CarouselSlide,
  StarFilled20,
  StarOutline20
} from '@sefar/design-system'
import React, { ComponentProps, useMemo, useState } from 'react'
import {
  NewsArticle,
  AUTH_PARAM_NAME,
  useMe,
  SpaceArticlePreview
} from '../../api'
import { PreviewImage } from '../../components/preview-image'
import { useDebounce, useMedia } from 'react-use'
import { Link, useSearchParams } from 'react-router-dom'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import dateFns from 'date-and-time'
import { BREAKPOINTS_QUERIES, styled } from '../../../../../../stitches.config'
import { HomeSearch } from './home-search'
import { HomeNewsPreview } from './home-news-preview'
import { HomeSpaceArticlesPreview } from './home-space-articles-preview'
import { ToolsBar } from '../../components/tools/tools-bar'
import { Pagination } from 'swiper'
import {
  analyticsPageTracker,
  formatArticleDate,
  mixpanelAnalyticsHelper
} from '../../components'
import { useTranslate } from '../../hooks/useTranslate'

export function Home() {
  const [searchParams, setSearchParams] = useSearchParams()
  const uid = searchParams?.get('uid')
  const csrf = searchParams?.get('csrf_token')
  const { t } = useTranslate()
  if (uid || csrf) {
    setSearchParams({}, { replace: true })
  }
  if (csrf) {
    localStorage.setItem(AUTH_PARAM_NAME, csrf)
    window.dispatchEvent(new Event('auth_token_change'))
  }
  const [focused, setFocused] = useState(false)
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)
  const isLg = useMedia(BREAKPOINTS_QUERIES.lg)

  const { me } = useMe()

  const greetingMessage = useMemo(() => {
    let dayTime = t('field_greetings_day')
    const currentHour = new Date().getHours()
    if (currentHour > 0 && currentHour < 10) {
      dayTime = t('field_greetings_morning')
    }
    if (currentHour > 16) {
      dayTime = t('field_greetings_evening')
    }

    return me?.firstName ? `${dayTime}, ${me.firstName}` : ''
  }, [me?.firstName, t])

  useDebounce(
    () => {
      analyticsPageTracker()
      mixpanelAnalyticsHelper().time('Leaving from home page')
      mixpanelAnalyticsHelper().track('Visit home page')
    },
    2000,
    []
  )

  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        css={{
          background: focused ? '$scrim' : '$darkenBackgroundHome',
          position: 'absolute',
          width: '100vw',
          height: '100%',
          bottom: 0,
          transition: 'none',
          pointerEvents: 'none'
        }}
      ></Box>
      <ContainerPadding
        css={{
          flex: 1,
          zIndex: focused ? 100 : 'initial',
          position: isSm ? 'initial' : 'absolute',
          '@sm': focused
            ? {
                height: 'var(--app-height)',
                mt: 0,
                top: -80,
                padding: 0,
                width: '100vw',
                d: 'flex'
              }
            : {}
        }}
      >
        {(!isSm || !focused) && (
          <Body1
            css={{
              color: '$white',
              textAlign: 'center',
              lineHeight: 1.25,
              mt: '$8',
              '@sm': { mt: '$12' }
            }}
          >
            {greetingMessage}
          </Body1>
        )}
        <HomeSearch focused={focused} setFocused={setFocused} />
      </ContainerPadding>

      <ContainerPadding
        css={
          !isLg
            ? {
                d: 'flex',
                alignItems: 'center',
                flexGrow: 2
              }
            : {}
        }
      >
        <Container>
          <Box css={{ my: '$2', '@md': { mt: '$43', mb: 0 } }}>
            <Space mb="SM" css={{ position: 'relative' }}>
              <Grid>
                <Box
                  css={{
                    gc: '1 / span 12',
                    gcLg: '2 / span 10'
                  }}
                >
                  <ToolsBar />
                </Box>
              </Grid>
            </Space>
            <Grid>
              <Box
                css={{
                  gc: '1 / span 12',
                  gcLg: '2 / span 10'
                }}
              >
                {isLg ? (
                  <Grid>
                    <PreviewContainer css={{ gcLg: '1 / span 6' }}>
                      <HomeNewsPreview />
                    </PreviewContainer>

                    <PreviewContainer
                      css={{ width: 'inherit', gcLg: '7 / span 6' }}
                    >
                      <HomeSpaceArticlesPreview />
                    </PreviewContainer>
                  </Grid>
                ) : (
                  <Carousel
                    loop={true}
                    css={{ '& .swiper-wrapper': { pb: '$8' } }}
                    spaceBetween={20}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                  >
                    <CarouselSlide>
                      <PreviewContainer>
                        <HomeNewsPreview />
                      </PreviewContainer>
                    </CarouselSlide>
                    <CarouselSlide>
                      <PreviewContainer>
                        <HomeSpaceArticlesPreview />
                      </PreviewContainer>
                    </CarouselSlide>
                  </Carousel>
                )}
              </Box>
            </Grid>
          </Box>
        </Container>
      </ContainerPadding>
    </Box>
  )
}

const PreviewContainer = styled(Box, {
  gc: '1 / span 12',
  color: '$white',
  borderRadius: 12,
  padding: '$4 $3',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(30px)'
})

const ArticleLink = styled(Link, {
  all: 'unset',
  transition: '$default',
  d: 'flex',
  cursor: 'pointer',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '$4',
  '&:hover': {
    px: '$3',
    ml: '-$3',
    backgroundColor: '$buttonBackgroundBlackBlur',
    borderColor: 'transparent'
  }
})

export function NewsArticleHomePreview({
  article,
  ...props
}: {
  article: NewsArticle
} & Omit<ComponentProps<typeof ArticleLink>, 'to'>) {
  return (
    <ArticleLink
      to={`/news/${article.id}`}
      {...props}
      onClick={() => {
        mixpanelAnalyticsHelper().track('Leaving from home page')
        analyticsPageTracker('news')
      }}
    >
      <Space
        my="3XS"
        mr="XS"
        css={{
          size: 64,
          backgroundColor: '$purpleLighten75',
          aspectRatio: '1 / 1',
          borderRadius: '$4'
        }}
      >
        <PreviewImage
          previewMedia={article.previewMedia}
          mediaType={article.mediaType}
          iconSize="24"
          iconStyles={{
            left: '1px',
            bottom: '1px'
          }}
        />
      </Space>
      <Space
        py="2XS"
        css={{
          flex: 1,
          d: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Body5 fontWeight="bold">
          <Clamp lines={2} maxLines={Number.MAX_SAFE_INTEGER}>
            {article.title}
          </Clamp>
        </Body5>
        <Body6 css={{ color: '$neutralLighten80' }}>{article.date}</Body6>
      </Space>
    </ArticleLink>
  )
}

export function SpaceArticleHomePreview({
  article,
  isBookmarked,
  onFavouriteClick,
  ...props
}: {
  article: SpaceArticlePreview
  isBookmarked: boolean
  onFavouriteClick: () => void
} & Omit<ComponentProps<typeof ArticleLink>, 'to'>) {
  const formattedDate = dateFns
    .parse(article.date, 'DD.MM.YYYY, HH:mm')
    .toISOString()

  return (
    <ArticleLink
      to={`/spaces/${article.id}`}
      {...props}
      onClick={() => {
        mixpanelAnalyticsHelper().track('Leaving from home page')
        analyticsPageTracker('spaces')
      }}
    >
      <Space
        my="3XS"
        mr="XS"
        css={{
          size: 64,
          backgroundColor: '$purpleLighten75',
          aspectRatio: '1 / 1',
          borderRadius: '$4'
        }}
      >
        <PreviewImage
          previewMedia={article.previewImageUrl ?? ''}
          iconSize="24"
          iconStyles={{
            left: '1px',
            bottom: '1px'
          }}
        />
      </Space>
      <Space
        py="2XS"
        css={{
          flex: 1,
          d: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Body5 fontWeight="bold">
          <Clamp lines={2} maxLines={Number.MAX_SAFE_INTEGER}>
            {article.title}
          </Clamp>
        </Body5>
        <Body6 css={{ color: '$neutralLighten80' }}>
          {article.spaceName} · {formatArticleDate(formattedDate)}
        </Body6>
      </Space>
      <Box
        onClickCapture={(e: React.SyntheticEvent<EventTarget>) => {
          e.preventDefault()
          onFavouriteClick()
        }}
        css={{ pl: '$4' }}
      >
        <Box
          as="span"
          css={{ color: isBookmarked ? '#FFBB38' : '$neutralLighten70' }}
        >
          {isBookmarked ? <StarFilled20 /> : <StarOutline20 />}
        </Box>
      </Box>
    </ArticleLink>
  )
}
