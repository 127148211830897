import React from 'react'
import { Body7, Box, LazyImage } from '@sefar/design-system'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import { Link } from 'react-router-dom'

export function AuthorCard({
  image,
  firstName,
  lastName,
  jobTitle,
  id,
  status,
  country,
  date
}: {
  id: string | undefined
  status: boolean | undefined
  image?: string
  firstName?: string
  lastName?: string
  jobTitle?: string
  country?: string
  date?: string
}) {
  const textColorStrong = {
    color: status ? '$neutral' : '$neutralLighten60'
  }
  const textColorWeek = {
    color: status ? '$neutralLighten30' : '$neutralLighten60'
  }

  return (
    <Box
      as={status ? Link : 'div'}
      to={status ? `/profile/${id}` : undefined}
      css={{
        all: 'unset',
        d: 'inline-flex',
        gap: '$2',
        alignItems: 'center',
        cursor: status ? 'pointer' : 'auto',
        transition: '$default',
        p: '$2',
        borderRadius: '$3',
        '&:hover': {
          backgroundColor: status ? '$primaryBlueLighten95' : 'none'
        }
      }}
    >
      <Box
        css={{
          size: 32,
          borderRadius: '$round',
          overflow: 'hidden',
          opacity: status ? 1 : 0.5
        }}
      >
        <LazyImage src={image || AvatarPlaceholder} aspectRatio="1 / 1" />
      </Box>
      <Box>
        <Body7 fontWeight="bold" css={{ ...textColorStrong }}>
          {firstName} {lastName}
          {country && <>, {country}</>}
        </Body7>
        <Body7 css={{ ...textColorWeek }}>{jobTitle}</Body7>
        {date && <Body7 css={{ ...textColorWeek }}>{date}</Body7>}
      </Box>
    </Box>
  )
}
