import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useToastContext, ToastTypeEnum } from '@sefar/design-system'
import { detailsSchema, useOrderState } from './use-order-state'
import {
  DEFAULT_STEP,
  MAP_QUERY_PARAM_TO_COMPONENT,
  PurchaseRequisitionStepsEnum,
  STEPS_ORDER
} from '../consts'
import { purchaseApi } from '../../api'
import { purchaseRequisitionsPageUrl } from '../../../../app'
import { useTranslate } from '../../../../hooks/useTranslate'
import { PurchaseRequisitionStatusType } from '../../consts'
import { ValidationError } from 'yup'

export const useOrderSteps = () => {
  const setToast = useToastContext()
  const navigate = useNavigate()
  const { t } = useTranslate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { reset, order, setErrors, errors } = useOrderState()
  const { id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const searchParamStepValue = searchParams?.get(
    'step'
  ) as PurchaseRequisitionStepsEnum

  const currentStepKey = searchParamStepValue || DEFAULT_STEP
  const currentStepIndex = STEPS_ORDER.findIndex(
    (stepName) => stepName === currentStepKey
  )
  const StepComponent = MAP_QUERY_PARAM_TO_COMPONENT[currentStepKey]
  const nextStepKey = STEPS_ORDER[currentStepIndex + 1]
  const hasNextStep = currentStepIndex < STEPS_ORDER.length - 1

  const validateDetails = () => {
    const { purchaseRequisitionItems, ...details } = order
    try {
      detailsSchema.validateSync(details, { abortEarly: false })
      setErrors(null)
      return true
    } catch (error) {
      if (error instanceof ValidationError) {
        const errors: { [a: string]: string } = {}
        if (error?.inner?.length) {
          for (const e of error.inner) {
            if (e.path) {
              errors[e.path] = e.message
            }
          }
          setErrors(errors)
          setToast({
            message: error.errors.join('\n'),
            type: ToastTypeEnum.error
          })
        }
        return false
      }
    }
  }

  const validateItems = () => {
    const { purchaseRequisitionItems } = order
    if (!purchaseRequisitionItems?.length) {
      setToast({
        message: t('field_error_purchase_requisition_items_empty'),
        type: ToastTypeEnum.error
      })
      return false
    }
    return true
  }

  const createOrder = async () => {
    setLoading(true)
    try {
      if (id) {
        await purchaseApi.updateOrderRequest({
          ...order,
          status: PurchaseRequisitionStatusType.Created
        })
      } else {
        await purchaseApi.createOrderRequest(order)
      }
    } catch (e) {
      setToast({
        message: t('field_error_common_toast_text'),
        type: ToastTypeEnum.error
      })
    }
    setLoading(false)
  }

  const saveAsDraft = async () => {
    setLoading(true)
    try {
      if (id) {
        await purchaseApi.updateOrderRequest(order)
      } else {
        await purchaseApi.createDraftOrderRequest(order)
      }
    } catch (e) {
      setToast({
        message: t('field_error_common_toast_text'),
        type: ToastTypeEnum.error
      })
    }
    setLoading(false)
    navigate(`/${purchaseRequisitionsPageUrl}`)
  }

  const goToNextStep = async () => {
    try {
      if (currentStepKey === PurchaseRequisitionStepsEnum.ITEMS) {
        const itemsAreValid = validateItems()
        if (!itemsAreValid) return
      }
      if (currentStepKey === PurchaseRequisitionStepsEnum.DETAILS) {
        const orderIsValid = validateDetails()
        if (!orderIsValid) return
      }
      if (currentStepKey === PurchaseRequisitionStepsEnum.OVERVIEW) {
        setLoading(true)
        await createOrder()
        setLoading(false)
      }
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set('step', nextStepKey)
        return prevSearchParams
      })
    } catch (error) {
      console.error('Error handling')
    }
  }
  const goToPrevStep = () => {
    if (currentStepIndex === 0) {
      navigate(`/${purchaseRequisitionsPageUrl}`)
      reset()
      return
    }

    const prevStepKey = STEPS_ORDER[currentStepIndex - 1]
    setSearchParams((prevSearchParams) => {
      prevSearchParams.set('step', prevStepKey)
      return prevSearchParams
    })
  }
  return {
    currentStepKey,
    currentStepIndex,
    StepComponent,
    nextStepKey,
    hasNextStep,
    goToNextStep,
    goToPrevStep,
    saveAsDraft,
    loading
  }
}
