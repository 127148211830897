import { AuthorCard } from '../common/author'
import { setLike, NewsArticle, SpaceArticle, Likes } from '../../api'
import { useConfigStore } from '../../state/config'
import React, { ComponentProps, useEffect, useState } from 'react'
import { Box, Like, StyledHeart } from '@sefar/design-system'
import * as Stitches from '@stitches/react'
import { CommentsCounter } from '../common/comments-counter'
import { ViewsCounter } from '../common/views-counter'

export interface ArticleInfo {
  drupalInternalNid: string
  commentCount: number
  likes?: Likes
  type?: string
  viewers?: string[]
}

export function ArticleInfoActions({
  article,
  withLikes = true,
  scrollToComments,
  css
}: {
  article: ArticleInfo
  withLikes: boolean
  scrollToComments?: () => void
  css?: Stitches.CSS
}) {
  return (
    <Box
      css={{ ml: 'auto', d: 'flex', gap: '$4', alignItems: 'center', ...css }}
    >
      {withLikes && <LikesCounter article={article} />}
      <CommentsCounter
        count={article?.commentCount}
        onClick={scrollToComments}
      />
      <ViewsCounter count={article?.viewers?.length ?? 0} />
    </Box>
  )
}

export const likeCounterTableStyles = {
  flexDirection: 'column',
  gap: '$1',
  border: '1px solid $neutralLighten93',
  borderRadius: '$4',
  padding: '$1 $2',
  '&.is-active': {
    background: '$neutralLighten97',
    borderColor: '$neutralLighten97'
  },
  '& .like-button .like-overlay': {
    size: 16
  },
  '& .like-count': {
    marginLeft: 0
  }
}

export function LikesCounter({
  article,
  likeProps
}: {
  article: Omit<ArticleInfo, 'commentCount'>
  likeProps?: ComponentProps<typeof StyledHeart>
}) {
  const { me: user } = useConfigStore()
  const [likesCount, setLikesCount] = useState<number>(0)
  const [isLikedByUser, setIsLikedByUser] = useState<boolean>(false)

  useEffect(() => {
    if (article?.likes?.clickedBy && user?.email) {
      const isLiked = article?.likes?.clickedBy.includes(user?.email)
      setIsLikedByUser(isLiked)
    }
  }, [article?.likes?.clickedBy, user?.email])

  useEffect(() => {
    article?.likes?.likes && setLikesCount(+article?.likes?.likes)
  }, [article?.likes?.likes])

  const onLikeClick = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault()
    setLike(article?.drupalInternalNid, article.type)
    isLikedByUser
      ? setLikesCount((old: number) => old - 1)
      : setLikesCount((old: number) => old + 1)
    setIsLikedByUser((oldValue) => !oldValue)
  }

  return (
    <Box css={{ d: 'flex' }}>
      <Like
        count={likesCount}
        onClick={onLikeClick}
        isActive={isLikedByUser}
        {...likeProps}
      />
    </Box>
  )
}

export function ArticleInfo({
  article,
  withLikes = true,
  scrollToComments
}: {
  article: NewsArticle | SpaceArticle
  withLikes?: boolean
  scrollToComments?: () => void
}) {
  return (
    <Box css={{ d: 'flex' }}>
      <AuthorCard
        id={article?.author?.id}
        image={article?.author?.image}
        firstName={article?.author?.firstName}
        lastName={article?.author?.lastName}
        jobTitle={article?.author?.jobTitle}
        status={article?.author?.status}
      />
      <ArticleInfoActions
        article={article}
        withLikes={withLikes}
        scrollToComments={scrollToComments}
      />
    </Box>
  )
}
