import {
  NewsArticle,
  SpaceArticle,
  SearchEntityTypes,
  SearchMeta,
  User,
  useSearch,
  saveRecentSearch,
  SubSpaceSimple
} from '../../api'
import { useDebounce, useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES, styled } from '../../../../../../stitches.config'
import {
  Body4,
  Body5,
  Body6,
  Box,
  CloseIcon16,
  Grid,
  Heading5,
  Input,
  LazyImage,
  SearchIcon20,
  Space,
  TabsBadge,
  TabsList,
  TabsTrigger
} from '@sefar/design-system'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import { UserDepartmentInfo } from '../profile/profile'
import React, { ComponentProps, useEffect, useState } from 'react'
// @ts-ignore
import Clamp from 'react-multiline-clamp'
import { PreviewImage } from '../../components/preview-image'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslate } from '../../hooks/useTranslate'

export const SEARCH_INIT_PARAMS = {
  sort: '-published_at',
  type: SearchEntityTypes.All,
  search: '',
  page: 0,
  country: ''
}

export const SearchEntityTypesLabels: Record<string, string> = {
  [SearchEntityTypes.All]: 'All',
  [SearchEntityTypes.News]: 'News',
  [SearchEntityTypes.People]: 'People',
  [SearchEntityTypes.Spaces]: 'Spaces Articles',
  [SearchEntityTypes.SubSpaces]: 'Spaces'
}

export const ArticleLinkHoverStyles = {
  px: '$4',
  ml: '-$4',
  mt: -1,
  pt: 1,
  backgroundColor: '$neutralLighten97',
  borderColor: 'transparent'
}
export const ArticleLink = styled(Link, {
  all: 'unset',
  transition: '$default',
  d: 'flex',
  cursor: 'pointer',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px $neutralLighten90 solid',
  borderRadius: '$4',
  '&:hover': ArticleLinkHoverStyles,
  boxSizing: 'border-box', // to have correct width on mobile
  '@md': { boxSizing: 'initial' } // important for hover
})

type TComponentSearchProps =
  | (Omit<ComponentProps<typeof Input>, 'onChange'> & {
      small?: boolean
      onChange: (value: string) => void
      onCancel?: any
    })
  | (Omit<ComponentProps<typeof Input>, 'onCancel'> & {
      onCancel?: any
      small?: boolean
      onChange: (value: string) => void
    })

const CloseIcon = styled(CloseIcon16, { cursor: 'pointer', marginRight: '0px' })
export function SearchInput({
  small = false,
  value,
  onChange,
  onCancel,
  ...props
}: TComponentSearchProps) {
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)
  const { t } = useTranslate()

  const onCancelHandler = () => {
    onChange('')
    onCancel && onCancel()
  }

  return (
    <Input
      autoComplete="off"
      placeholder={
        isMd ? t('field_search_input_text') : t('field_search_input_text_sm')
      }
      startEnhancer={<SearchIcon20 />}
      endEnhancer={
        <Box
          css={{
            d: 'flex',
            transitionProperty: 'background-color',
            borderRadius: '50%',
            mr: '-$2',
            py: small ? 0 : '$2',
            px: value && !small ? '$2' : 0,
            '&:hover': { backgroundColor: small ? '' : '$neutralLighten93' }
          }}
        >
          <CloseIcon
            data-cy="clearSearchInputButton"
            css={{ w: value ? 'auto' : 0 }}
            onClick={() => onCancelHandler()}
          />
        </Box>
      }
      value={value}
      id="search-input"
      containerProps={{ css: { width: '100%' } }}
      inputContainerProps={{
        css: {
          borderRadius: 26,
          borderColor: 'transparent',
          boxShadow: 'none',
          '&:hover, &:focus-within, &.__hover, &.__focus': {
            backgroundColor: '$white',
            outline: 'none',
            borderColor: 'transparent',
            boxShadow: 'none'
          }
        }
      }}
      onChange={({ currentTarget }) => onChange(currentTarget.value)}
      {...props}
    />
  )
}

export function PeopleSearchResultCard({
  user,
  small,
  hideBorder = false,
  ...props
}: {
  user: User
  small?: boolean
  hideBorder?: boolean
} & Omit<ComponentProps<typeof ArticleLink>, 'to'>) {
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)

  return (
    <ArticleLink
      to={`/profile/${user.id}`}
      css={hideBorder ? { borderBottom: 0 } : {}}
      {...props}
    >
      <Grid>
        <Box
          css={{
            color: '$neutral',
            gc: '1 / span 12',
            gcMd: '1 / span 6',
            d: 'flex'
          }}
        >
          <Space
            my="XS"
            mr="SM"
            css={{
              size: isMd && !small ? 56 : 32,
              borderRadius: '$round',
              overflow: 'hidden'
            }}
          >
            <LazyImage
              src={user?.image || AvatarPlaceholder}
              aspectRatio="1 / 1"
            />
          </Space>
          <Space
            py="2XS"
            css={{
              flex: 1,
              d: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            {small ? (
              <Body5 css={{ mb: '$1', '@md': { mb: '$1' } }} fontWeight="bold">
                {user.firstName} {user.lastName}{' '}
                {user.nickname ? `(${user.nickname})` : ''}
              </Body5>
            ) : (
              <Heading5 css={{ mb: '$1', '@md': { mb: '$1' } }}>
                {user.firstName} {user.lastName}{' '}
                {user.nickname ? `(${user.nickname})` : ''}
              </Heading5>
            )}
            <Body6>
              <UserDepartmentInfo user={user} />
            </Body6>
          </Space>
        </Box>
        {isMd && (
          <Box
            css={{ gc: '7 / span 6', display: 'flex', alignItems: 'center' }}
          >
            <Space py="SM">
              <Body6 fontWeight="bold" css={{ color: '$neutralLighten30' }}>
                {user.officePhone}
              </Body6>
            </Space>
          </Box>
        )}
      </Grid>
    </ArticleLink>
  )
}

export function ArticleSearchResultCard({
  article,
  link,
  hideBorder = false
}: {
  article: NewsArticle | SpaceArticle
  link: string
  hideBorder?: boolean
}) {
  const isMd = useMedia(BREAKPOINTS_QUERIES.md)

  return (
    <ArticleLink to={link} css={hideBorder ? { borderBottom: 0 } : {}}>
      <Space py="SM" css={{ color: '$neutral', flex: 1 }}>
        <Heading5 css={{ mb: '$2', '@md': { mb: '$1' } }}>
          <Clamp lines={2} maxLines={Number.MAX_SAFE_INTEGER}>
            {article.title}
          </Clamp>
        </Heading5>
        <Body4 css={{ mb: '$3' }}>
          <Clamp lines={isMd ? 2 : 3} maxLines={Number.MAX_SAFE_INTEGER}>
            <Box dangerouslySetInnerHTML={{ __html: article.lead }} />
          </Clamp>
        </Body4>
        <Body6 css={{ color: '$neutralLighten30' }}>{article.date}</Body6>
      </Space>
      <Space py="SM" ml="3XS">
        <PreviewImage
          previewMedia={article.previewMedia}
          mediaType={article.mediaType}
          iconSize="32"
          wrapperCss={{
            borderRadius: '$4',
            aspectRatio: '3 / 2',
            width: isMd ? 144 : 84
          }}
          iconStyles={{
            left: '8px',
            bottom: '4px'
          }}
        />
      </Space>
    </ArticleLink>
  )
}

export function ArticleSearchResultCardSmall({
  article,
  link,
  ...props
}: {
  article: NewsArticle | SpaceArticle
  link: string
} & Omit<ComponentProps<typeof ArticleLink>, 'to'>) {
  return (
    <ArticleLink to={link} {...props}>
      <Space my="XS" mr="SM">
        <PreviewImage
          previewMedia={article.previewMedia}
          mediaType={article.mediaType}
          iconSize="16"
          wrapperCss={{
            size: '32px',
            borderRadius: '$4'
          }}
        />
      </Space>
      <Space
        py="2XS"
        css={{
          color: '$neutral',
          flex: 1,
          d: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Body5 css={{ mb: '$2', '@md': { mb: '$1' } }} fontWeight="bold">
          <Clamp lines={1} maxLines={Number.MAX_SAFE_INTEGER}>
            {article.title}
          </Clamp>
        </Body5>
        <Body6 css={{ color: '$neutralLighten30' }}>{article.date}</Body6>
      </Space>
    </ArticleLink>
  )
}

export function SubSpaceSearchResultCardSmall({
  subSpace,
  link,
  hideBorder,
  ...props
}: {
  subSpace: SubSpaceSimple
  link: string
  hideBorder?: boolean
} & Omit<ComponentProps<typeof ArticleLink>, 'to'>) {
  return (
    <ArticleLink
      to={link}
      css={hideBorder ? { borderBottom: 0 } : {}}
      {...props}
    >
      <Space my="XS" mr="SM">
        <PreviewImage
          previewMedia={subSpace.imageUrl || ''}
          iconSize="16"
          wrapperCss={{
            width: '68px',
            height: '56px',
            borderRadius: '$4'
          }}
        />
      </Space>
      <Space
        py="2XS"
        css={{
          color: '$neutral',
          flex: 1,
          d: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Body5 css={{ mb: '$2', '@md': { mb: '$1' } }} fontWeight="bold">
          <Clamp lines={1} maxLines={Number.MAX_SAFE_INTEGER}>
            {subSpace.title}
          </Clamp>
        </Body5>
      </Space>
    </ArticleLink>
  )
}

export function SearchTabList({
  totalCount,
  meta,
  ...props
}: {
  totalCount: number
  meta: {
    [SearchEntityTypes.News]: SearchMeta
    [SearchEntityTypes.People]: SearchMeta
    [SearchEntityTypes.Spaces]: SearchMeta
    [SearchEntityTypes.SubSpaces]: SearchMeta
  }
} & ComponentProps<typeof TabsList>) {
  const { t } = useTranslate()

  return (
    <TabsList aria-label="Search entity types" {...props}>
      <TabsTrigger value={SearchEntityTypes.All}>
        <span>{t('field_all')}</span>
        <TabsBadge>{totalCount}</TabsBadge>
      </TabsTrigger>
      <TabsTrigger value={SearchEntityTypes.News}>
        <span>{t('field_news')}</span>
        <TabsBadge>{meta?.[SearchEntityTypes.News]?.count || 0}</TabsBadge>
      </TabsTrigger>
      <TabsTrigger value={SearchEntityTypes.People}>
        <span>{t('field_people')}</span>
        <TabsBadge>{meta?.[SearchEntityTypes.People]?.count || 0}</TabsBadge>
      </TabsTrigger>
      <TabsTrigger value={SearchEntityTypes.Spaces}>
        <span>{t('field_spaces')}</span>
        <TabsBadge>
          {meta?.[SearchEntityTypes.Spaces]?.count +
            meta?.[SearchEntityTypes.SubSpaces]?.count || 0}
        </TabsBadge>
      </TabsTrigger>
    </TabsList>
  )
}

export function useSearchLogic() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchParamsUi, setSearchParamsUi] =
    useState<Record<string, string | number | SearchEntityTypes>>(
      SEARCH_INIT_PARAMS
    )
  const { news, meta, isLoading, people, spaces, subSpaces } = useSearch(
    Object.fromEntries([...searchParams])
  )
  const totalCount =
    meta &&
    Object.values(meta).reduce((res, value) => res + (value?.count || 0), 0)

  const changeSearchParamsUi = (
    newParams: Record<string, string | number | SearchEntityTypes>
  ) => {
    setSearchParamsUi((prev) => ({ ...prev, ...newParams }))
  }

  const resetSearchParams = () => {
    setSearchParamsUi({
      ...SEARCH_INIT_PARAMS,
      search: searchParamsUi.search,
      type: searchParamsUi.type
    })
  }

  useEffect(() => {
    setSearchParamsUi((params) => ({
      ...params,
      ...Object.fromEntries([...searchParams])
    }))
  }, [])

  useDebounce(
    () => {
      if (searchParamsUi.search) {
        saveRecentSearch(searchParamsUi.search)
      }
      setSearchParams(
        Object.entries(searchParamsUi).reduce(
          (res: Record<string, string>, [key, value]) => {
            if (value) {
              res[key] = value
            }
            return res
          },
          {}
        ),
        { replace: true }
      )
    },
    1000,
    [searchParamsUi]
  )

  const changeType = (type: SearchEntityTypes) => {
    searchParams.set('page', 0)
    searchParams.set('type', type)
    setSearchParams(searchParams)
    changeSearchParamsUi({ type, page: 0 })
  }

  return {
    news,
    meta,
    isLoading,
    people,
    spaces,
    subSpaces,
    totalCount,
    searchParamsUi,
    changeSearchParamsUi,
    resetSearchParams,
    changeType
  }
}
