import {
  Body3,
  Box,
  ChevronLeftIcon16,
  Container,
  ContainerPadding,
  Space,
  Spinner,
  ToastTypeEnum,
  useToastContext
} from '@sefar/design-system'
import {
  NewsArticleSettings,
  NewsDetailsSettings
} from './news-details-settings'
import {
  MANDATORY_FIELDS_INFO,
  NewsDetailsActions,
  saveNewsArticle
} from './news-details-actions'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ArticleEditor } from '../../components'
import { ChannelTypeEnum, NewsArticle, UploadProfiles } from '../../api'
import { useMedia } from 'react-use'
import { BREAKPOINTS_QUERIES, styled } from '../../../../stitches.config'
import { myContentPageUrl } from '../../app'
import { Params, useNavigate } from 'react-router-dom'
import { useLocationStateFrom } from '../../hooks/useLocationStateFrom'
import { useConfigStore } from '../../state/config'
import { NewsEditorNote } from './news-editor-note'

const StyledSaveAndCloseBtn = styled('button', {
  d: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  ':disabled': {
    color: '$neutralLighten60',
    cursor: 'not-allowed',
    pointerEvents: 'none'
  }
})

export function NewsDetailsEdit({
  article,
  lang,
  setArticle,
  isNew
}: {
  article: NewsArticle
  lang: string
  setArticle: Dispatch<SetStateAction<NewsArticle>>
  isNew: boolean
}) {
  const { me } = useConfigStore()
  const from = useLocationStateFrom([], () => `/${myContentPageUrl}`)
  const setToast = useToastContext()
  const navigate = useNavigate()
  const isSm = useMedia(BREAKPOINTS_QUERIES.sm)

  const [requestInProgress, setRequestInProgress] = useState<boolean>(false)
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false)

  const [settings, setSettings] = useState<NewsArticleSettings>({
    langcode: 'en',
    field_location: [],
    field_news_category: [],
    field_news_must_read: false,
    channelType: ChannelTypeEnum.global
  })

  const [isReadyToSave, setIsReadyToSave] = useState<boolean>(false)

  useEffect(() => {
    const location =
      article?.location?.filter((c) => c !== ChannelTypeEnum.global) || []

    setSettings((oldValue) => ({
      ...oldValue,
      field_location: location,
      channelType: location?.length
        ? ChannelTypeEnum.counties
        : ChannelTypeEnum.global,
      field_news_category: article?.categories ?? [],
      field_news_author: article?.author ?? me,
      field_news_must_read: article?.isMustRead ?? false
    }))
  }, [article?.location, article?.categories, article?.isMustRead, me?.id])

  const onSaveAndCancelButtonCLick = () => {
    setRequestInProgress(true)
    saveNewsArticle(article, settings)
      .then((res) => {
        if (res?.errors?.length) {
          setToast({
            message: 'Some error occurred. Please try again later!',
            type: ToastTypeEnum.error
          })
        } else {
          setToast({
            message: 'The article was successfully created!',
            type: ToastTypeEnum.success
          })
          navigate(`/${myContentPageUrl}`)
        }
      })
      .finally(() => {
        setRequestInProgress(false)
      })
  }

  useEffect(() => {
    if (
      lang &&
      settings?.channelType &&
      !requestInProgress &&
      article?.text &&
      article?.title &&
      article?.lead &&
      article?.media?.url
    ) {
      setIsReadyToSave(true)
    } else {
      setIsReadyToSave(false)
    }
  }, [
    lang,
    settings?.channelType,
    requestInProgress,
    article?.media?.url,
    article
  ])

  useEffect(() => {
    if (article?.lang && article?.lang !== settings?.langcode) {
      setSettings((oldValue) => ({
        ...oldValue,
        langcode: article?.lang
      }))
    }
  }, [article?.lang])

  return (
    <>
      {requestInProgress && (
        <Space
          pt="4XL"
          css={{
            position: 'absolute',
            top: 'auto',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            background: '$scrim',
            zIndex: '$tooltip'
          }}
        >
          <Spinner />
        </Space>
      )}

      {isSm && (
        <Box
          css={{
            position: 'fixed',
            top: '0',
            width: '100%',
            background: '$white',
            height: '57px',
            left: '0',
            zIndex: 100,
            boxShadow: '0 8px 4px 0 rgba(0,0,0,0.04)'
          }}
        >
          <Container css={{ height: '100%' }}>
            <ContainerPadding css={{ height: '100%' }}>
              <Box
                css={{
                  d: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '$4',
                  height: '100%'
                }}
              >
                <StyledSaveAndCloseBtn
                  data-cy="saveAndCloseButton"
                  onClick={() => {
                    if (isReadyToSave) {
                      onSaveAndCancelButtonCLick()
                    } else if (isSm) {
                      setToast({
                        type: ToastTypeEnum.warning,
                        message: MANDATORY_FIELDS_INFO
                      })
                    }
                  }}
                >
                  <Box
                    as="span"
                    css={{
                      d: 'flex',
                      alignItems: 'center',
                      mr: '0.5rem'
                    }}
                  >
                    <ChevronLeftIcon16 />
                  </Box>
                  <Body3>
                    {article?.status ? 'Save & Publish' : 'Save & Close'}
                  </Body3>
                </StyledSaveAndCloseBtn>
              </Box>

              {/*<Space ml="LG">*/}
              {/*  <Settings20 />*/}
              {/*</Space>*/}
            </ContainerPadding>
          </Container>
        </Box>
      )}
      <ArticleEditor
        article={article}
        isEditorFocused={isEditorFocused}
        onArticleChange={(value) =>
          setArticle((oldValue) => ({ ...oldValue, ...value }))
        }
        onEditorFocusChange={setIsEditorFocused}
        uploadProfile={UploadProfiles.newsUploads}
        contentType="news"
      ></ArticleEditor>

      <Space>
        <NewsEditorNote note={article?.reviewNote} />
      </Space>

      <Space css={{ '@sm': { mb: '$3' } }}>
        <NewsDetailsSettings
          isNew={isNew}
          settings={settings}
          onSettingsUpdate={(update) =>
            setSettings((oldValue) => ({ ...oldValue, ...update }))
          }
        />
      </Space>

      <Box
        css={{
          display: isSm && isEditorFocused ? 'none' : 'block',
          position: 'fixed',
          bottom: '0',
          width: '100%',
          background: '$neutralLighten20',
          height: '$inlineEditingBottomToolbar',
          left: '0',
          zIndex: 100
        }}
      >
        <Container css={{ height: '100%' }}>
          <ContainerPadding css={{ height: '100%' }}>
            <NewsDetailsActions
              article={article}
              settings={settings}
              onCancelButtonCLick={() => navigate(from)}
              setRequestInProgress={setRequestInProgress}
              isReadyToSave={isReadyToSave}
            />
          </ContainerPadding>
        </Container>
      </Box>
    </>
  )
}
