import { styled } from '../../../../../../stitches.config'
import { motion } from 'framer-motion'
import {
  ArrowRightIcon16,
  Badge,
  Body5,
  Body5Styles,
  Body7,
  Box,
  Space,
  Tooltip
} from '@sefar/design-system'
import React, { ReactNode } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslate } from '../../hooks/useTranslate'
import { useConfigStore } from '../../state/config'
import { API_DOMAIN, ReviewStateEnum } from '../../api'
import { formatDateTimeOfficial } from '../../components'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import * as Stitches from '@stitches/react'

export const TH = styled('th', Body5Styles)
export const TD = styled('td', Body5Styles)
export const TR = styled('tr', {
  variants: {
    header: {
      true: {
        backgroundColor: '$neutralLighten93'
      },
      false: {
        backgroundColor: '$white'
      }
    },
    hover: {
      true: {
        '&:hover': {
          backgroundColor: '$primaryBlueLighten97',
          '@lg': {
            '& td:last-child, & td a': { color: '$primaryBlue' }
          }
        }
      },
      false: {}
    }
  },
  defaultVariants: {
    hover: 'false',
    header: 'false'
  }
})
export const MyContentArticleRowAnimated = motion(TR)
export const MyContentArticleRowAnimation = {
  transition: {
    type: 'spring',
    bounce: 0,
    duration: 0.2
  },
  initial: { top: -200, zIndex: -1 },
  animate: {
    top: 0,
    transitionEnd: {
      zIndex: 0
    }
  },
  exit: { top: -200, zIndex: -1 }
}

export const Table = styled('table', {
  width: '100%',
  color: '$neutral',
  verticalAlign: 'middle',
  '& thead': {
    position: 'sticky',
    top: 0,
    zIndex: 2
  },
  '& th': {
    textAlign: 'left',
    fontWeight: '$bold',
    padding: '$3',
    whiteSpace: 'nowrap',
    '&.pl-8': {
      pl: '$8'
    }
  },
  '& td': {
    verticalAlign: 'middle',
    padding: '$4 $3',
    lineHeight: 0,
    '@lg': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '300px' // to make textOverflow work
    }
  }
})

const ArrowRightIcon16Styled = styled(ArrowRightIcon16, {})

export function LastUpdateHeader({ title }: { title: string }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslate()
  return (
    <Box
      css={{
        d: 'inline-flex',
        gap: '$2',
        alignItems: 'center'
      }}
      onClick={() => {
        searchParams.set('sort', ((+searchParams.get('sort') || 0) + 1) % 2)
        setSearchParams(searchParams)
      }}
    >
      <Body5 as="span">{title || t('field_last_update')}</Body5>
      <ArrowRightIcon16Styled
        css={{
          transform: `rotate(${+searchParams.get('sort') ? -90 : 90}deg)`
        }}
      />
    </Box>
  )
}

export function ReviewStatus({ article, onFeedbackWithMessageClick }) {
  const { me } = useConfigStore()
  const isOnReview = article?.review_state === ReviewStateEnum['On review']
  const isReviewDone = article?.review_state === ReviewStateEnum.Reviewed
  const isReviewedWithFeedback =
    article?.review_state === ReviewStateEnum['Review with feedback']

  return isOnReview ? (
    <Space
      my="4XS"
      css={{
        d: 'flex',
        flexDirection: 'column',
        gap: '$1',
        '@lg': {
          flexDirection: 'row',
          gap: '$2',
          flexWrap: 'wrap',
          alignItems: 'center'
        }
      }}
    >
      <Box>
        {me?.id !== article?.uid ? (
          <Badge status="pending">
            review pending
            {article?.field_news_note_for_review && (
              <>
                {' '}
                <Box
                  as="button"
                  css={{ all: 'unset', cursor: 'pointer' }}
                  onClick={() => {
                    onFeedbackWithMessageClick &&
                      onFeedbackWithMessageClick(
                        article?.field_news_note_for_review
                      )
                  }}
                >
                  (with note)
                </Box>
              </>
            )}
          </Badge>
        ) : (
          <Badge status="progress">
            in review
            {article?.field_news_note_for_review && (
              <>
                {' '}
                <Box
                  as="button"
                  css={{ all: 'unset', cursor: 'pointer' }}
                  onClick={() => {
                    onFeedbackWithMessageClick &&
                      onFeedbackWithMessageClick(
                        article?.field_news_note_for_review
                      )
                  }}
                >
                  (with note)
                </Box>
              </>
            )}
          </Badge>
        )}
      </Box>

      {article?.field_sent_to_review && (
        <Body7 css={{ color: '$neutralLighten30' }}>
          sent on {formatDateTimeOfficial(article.field_sent_to_review)}
        </Body7>
      )}
    </Space>
  ) : isReviewDone && !article?.status ? (
    <Space my="4XS">
      <Badge status="success">review done</Badge>
    </Space>
  ) : isReviewedWithFeedback && !article?.status ? (
    <Space my="4XS">
      <Box
        as="button"
        css={{ all: 'unset' }}
        onClick={() => {
          onFeedbackWithMessageClick &&
            onFeedbackWithMessageClick(article?.field_news_review_feedback)
        }}
      >
        <Badge status="pending" variant="clickable">
          review with feedback
        </Badge>
      </Box>
    </Space>
  ) : (
    <></>
  )
}

export function getArticleStatus(article): ReactNode {
  return article?.published ? (
    <>Published</>
  ) : article?.publish_on ? (
    <>
      Planned
      <Body7 css={{ color: '$neutralLighten30' }}>
        {formatDateTimeOfficial(article?.publish_on)}
      </Body7>
    </>
  ) : (
    <>Unpublished</>
  )
}

function UserAvatar({
  id,
  fullName,
  image = AvatarPlaceholder,
  css,
  accented
}: {
  image: string
  id: string
  fullName?: string
  css?: Stitches.CSS
  accented?: boolean
}) {
  const accentedStyles = accented
    ? {
        size: 36,
        border: '2px solid transparent',
        top: '2px',
        backgroundImage: `url("${image}"), linear-gradient(90deg, #E32127 0%, #0073BE 100%)`
      }
    : {}
  return (
    <Tooltip
      text={fullName ?? ''}
      active={!!fullName?.trim()}
      withArrow={false}
    >
      <Box
        as={Link}
        to={`/profile/${id}`}
        css={{
          position: 'relative',
          size: 32,
          borderRadius: '$round',
          overflow: 'hidden',
          d: 'inline-block',
          backgroundOrigin: 'border-box',
          backgroundClip: 'content-box, border-box',
          backgroundSize: 'cover',
          backgroundImage: `url("${image}")`,
          ...accentedStyles,
          ...css
        }}
      ></Box>
    </Tooltip>
  )
}

export function AuthorEditorAvatars({ article }) {
  const isAuthorCreator = article?.uid === article?.field_news_author
  const creatorAvatarUrl =
    article?.creator_avatar && `${API_DOMAIN}${article?.creator_avatar}`
  const articleAuthorAvatarUrl =
    article?.news_author_avatar && `${API_DOMAIN}${article?.news_author_avatar}`
  const creatorFullName = `${article?.creator_first_name || ''} ${
    article?.creator_last_name || ''
  }`
  const authorFullName = `${article?.news_author_first_name || ''} ${
    article?.news_author_last_name || ''
  }`

  return (
    <>
      {article?.field_news_author && !isAuthorCreator && (
        <UserAvatar
          id={article?.uid}
          image={creatorAvatarUrl}
          fullName={creatorFullName}
        />
      )}
      <UserAvatar
        id={article?.field_news_author ?? article?.uid}
        image={
          article?.field_news_author ? articleAuthorAvatarUrl : creatorAvatarUrl
        }
        fullName={article?.field_news_author ? authorFullName : creatorFullName}
        accented={true}
        css={{
          size: 36,
          left: '-8px'
        }}
      />
    </>
  )
}
