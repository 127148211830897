import React, { MouseEvent, useEffect, useState } from 'react'
import {
  Body3,
  Box,
  Button,
  ContainerPadding,
  Container,
  TextLink,
  Space,
  Tabs,
  TabsTrigger,
  TabsList,
  DropdownMenu,
  DropdownMenuItem,
  ChevronUpIcon16,
  Spinner
} from '@sefar/design-system'
import { AddNewArticleButton } from '../../components'
import { NewsArticleCard } from '../../components/news'
import { styled } from '../../../../../../stitches.config'
import {
  SearchEntityTypes,
  useCountries,
  NewsArticle,
  ChannelTypeEnum,
  isRegularUser,
  SEARCH_PAGE_LIMIT,
  ALL_COUNTRIES_REGION,
  useNewsInfinite,
  useMustReadNews
} from '../../api'
import { useTranslate } from '../../hooks/useTranslate'
import { Link, useSearchParams } from 'react-router-dom'
import { newsCreatePageUrl } from '../../app'
import {
  ScrollDirectionEnum,
  useScrollDirection
} from '../../hooks/useScrollDirection'
import { useUpdateEffect } from 'react-use'
import { useConfigStore } from '../../state/config'
import { localStorageKeys, sessionStorageKeys } from '../../constants'
import { MustReadNewsRow } from '../../components/news/must-read-news-row'

export const NewsOverviewContainer = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  my: '$8',
  '@sm': { gap: '$4' }
})
const StyledChevronUpIcon16 = styled(ChevronUpIcon16, {})

export function NewsOverview() {
  const { me } = useConfigStore()
  const { countries } = useCountries()
  const [searchParams, setSearchParams] = useSearchParams()
  const [regionFilter, setRegionFilter] = useState<string>(() => {
    const cachedFilter = sessionStorage.getItem(
      sessionStorageKeys.NEWS_CHOSEN_REGION
    )
    const currentSearch = searchParams.get('region')
    if (currentSearch) {
      return currentSearch
    } else if (cachedFilter) {
      return cachedFilter
    } else {
      return ChannelTypeEnum.all
    }
  })
  const [selectedCountry, setSelectedCountry] = useState<string>(
    localStorage.getItem(localStorageKeys.NEWS_CHOSEN_COUNTRY) || ''
  )
  const [isShowNextButton, setIsShowNextButton] = useState(false)
  const { news, page, setPage, isLoading, next } = useNewsInfinite(
    regionFilter,
    selectedCountry,
    SEARCH_PAGE_LIMIT
  )

  const { mustReadNews = [] } = useMustReadNews(
    me?.internalUid,
    SEARCH_PAGE_LIMIT
  )
  const shouldShowMustReadNews = [
    ChannelTypeEnum.global,
    ChannelTypeEnum.all
  ].includes(regionFilter as ChannelTypeEnum)

  const { t } = useTranslate()
  const [scrollDirection, y] = useScrollDirection()

  useEffect(() => {
    const cachedFilter = sessionStorage.getItem(
      sessionStorageKeys.NEWS_CHOSEN_REGION
    )
    const currentSearch = searchParams.get('region')
    if (cachedFilter && !currentSearch) {
      setSearchParams({ region: cachedFilter })
    }
  }, [])

  useUpdateEffect(() => {
    if (regionFilter === ChannelTypeEnum.all) {
      searchParams.delete('region')
      setSearchParams(searchParams)
    } else {
      setSearchParams({ region: regionFilter })
    }
  }, [searchParams, regionFilter, setPage])

  useEffect(() => {
    if (!isLoading) {
      setIsShowNextButton(next)
    }
  }, [isLoading, next])

  return (
    <>
      <Box
        css={{
          position: 'fixed',
          mt:
            scrollDirection === ScrollDirectionEnum.Down
              ? '-50%'
              : '$headerHeight',
          width: '100%',
          backgroundColor: '$white',
          borderBottom: '1px $neutralLighten90 solid',
          transitionProperty: 'all',
          transitionTimingFunction: '$easingMove',
          transitionDuration: '500ms',
          boxShadow: y ? '$dropdown' : '',
          zIndex: '$header'
        }}
      >
        <ContainerPadding css={{ flex: 1 }}>
          <Container size="small">
            <Space mt="XS">
              <Tabs
                value={regionFilter}
                onValueChange={(value) => {
                  setPage(1)
                  setIsShowNextButton(false)
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                  if (Object.values(countries || {})?.includes(value)) {
                    setSelectedCountry(value)
                    localStorage.setItem(
                      localStorageKeys.NEWS_CHOSEN_COUNTRY,
                      value
                    )
                  }
                  setRegionFilter(value)
                  sessionStorage.setItem(
                    sessionStorageKeys.NEWS_CHOSEN_REGION,
                    value
                  )
                }}
              >
                <TabsList
                  aria-label="News target area"
                  css={{ d: 'flex', justifyContent: 'center' }}
                >
                  <TabsTrigger data-cy="NewsTabAll" value={ChannelTypeEnum.all}>
                    {t('field_news_tab_my_news')}
                  </TabsTrigger>
                  <TabsTrigger
                    data-cy="NewsTabGlobal"
                    value={ChannelTypeEnum.global}
                  >
                    {t('field_news_tab_global')}
                  </TabsTrigger>
                  <TabsTrigger
                    data-cy="NewsTabSelectedCountry"
                    value={selectedCountry}
                    disabled={!selectedCountry}
                    css={{
                      '@xs': {
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginRight: '0.4rem'
                      },
                      '@xxs': {
                        marginRight: '0.2rem'
                      }
                    }}
                  >
                    {selectedCountry || t('field_select_country')}
                  </TabsTrigger>

                  <DropdownMenu
                    trigger={(isOpen) => (
                      <Space
                        data-cy="NewsTabSelect"
                        onClick={(event) => event.stopPropagation()}
                        my="3XS"
                        pl="XS"
                        css={{
                          borderLeft: '1px solid $neutralLighten80',
                          height: 16
                        }}
                      >
                        <StyledChevronUpIcon16
                          onClick={(event: MouseEvent<HTMLOrSVGElement>) =>
                            event.stopPropagation()
                          }
                          css={{
                            transform: isOpen
                              ? 'translateY(9%)'
                              : 'rotate(180deg)'
                          }}
                        />
                      </Space>
                    )}
                    content={() => (
                      <>
                        {countries &&
                          [ALL_COUNTRIES_REGION, ...countries].map(
                            (country: string, index: number) => (
                              <DropdownMenuItem
                                key={country}
                                data-cy={`NewsTabOption-${index}`}
                                onClick={() => {
                                  setPage(1)
                                  setIsShowNextButton(false)
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                  })
                                  setSelectedCountry(country)
                                  setRegionFilter(country)
                                  localStorage.setItem(
                                    localStorageKeys.NEWS_CHOSEN_COUNTRY,
                                    country
                                  )
                                  sessionStorage.setItem(
                                    sessionStorageKeys.NEWS_CHOSEN_REGION,
                                    country
                                  )
                                }}
                                active={selectedCountry === country}
                              >
                                {country}
                              </DropdownMenuItem>
                            )
                          )}
                      </>
                    )}
                    contentProps={{
                      align: 'center',
                      side: 'bottom',
                      css: { maxHeight: 200, overflowY: 'auto' }
                    }}
                  />
                </TabsList>
              </Tabs>
            </Space>
          </Container>
        </ContainerPadding>
      </Box>
      <Box css={{ d: 'flex', flexDirection: 'column', height: '100%' }}>
        {shouldShowMustReadNews && (
          <MustReadNewsRow mustReadNews={mustReadNews} />
        )}
        <ContainerPadding css={{ flex: 1 }}>
          <Container
            size="small"
            css={{ mb: '$8', mt: mustReadNews.length ? 0 : '$22' }}
          >
            <NewsOverviewContainer data-cy="newsOverviewContainer">
              {news?.map((article: NewsArticle) => (
                <Box key={article.id} id={article.id}>
                  <NewsArticleCard key={article.id} article={article} />
                </Box>
              ))}
            </NewsOverviewContainer>

            {isLoading && <Spinner />}

            {isShowNextButton && (
              <Box
                css={{
                  d: 'flex',
                  justifyContent: 'center',
                  mt: '$27',
                  mb: '$12',
                  '@sm': {
                    mt: '$24',
                    mb: '$6'
                  }
                }}
              >
                <Button
                  disabled={isLoading}
                  variant="secondary"
                  onClick={() => setPage(page + 1)}
                >
                  {t('field_load_more', ['20'])}
                </Button>
              </Box>
            )}

            <Space mt="2XL">
              <Body3
                css={{
                  d: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <Box as="span" css={{ mr: '$1' }}>
                  {t('field_looking_for_news')}
                </Box>
                <Box>
                  <Box as="span" css={{ mr: '$1' }}>
                    {t('field_go_to')}
                  </Box>
                  <TextLink
                    to={`/search?type=${SearchEntityTypes.News}`}
                    as={Link}
                    variant="inline"
                    css={{ color: '$black', borderColor: '$black' }}
                  >
                    {t('field_news_archive')}
                  </TextLink>
                </Box>
              </Body3>
            </Space>
          </Container>
        </ContainerPadding>

        {!isRegularUser(me) && (
          <AddNewArticleButton
            url={`/${newsCreatePageUrl}`}
            text={t('field_add_news')}
            padding={true}
          />
        )}
      </Box>
    </>
  )
}
