import React, { useMemo, useEffect, useState } from 'react'
import { checkIsWebUri } from '../../utils/url'
import { getFaviconUrl, isDeepEqual } from '../utils'
import {
  useDefaultTools,
  addBookmark,
  deleteBookmark,
  Bookmark,
  Tool,
  ToolsTypes,
  getToolIconByUrl,
  patchUserTools,
  transferTool
} from '../../api'
import { useConfigStore } from '../../state/config'

import {
  Box,
  Button,
  Heading5,
  Space,
  Body5,
  Dialog,
  Input,
  Spinner,
  useToastContext,
  ToastTypeEnum,
  ToolCard,
  AddToolCardButton,
  EditToolCardButton
} from '@sefar/design-system'
import { useTranslate } from '../../hooks/useTranslate'

const bookmarkInitialState = {
  link: '',
  name: ''
}

export function ToolsBar() {
  const { me: user, setMe, translations } = useConfigStore()
  const { defaultTools, isLoading } = useDefaultTools()
  const setToast = useToastContext()
  const { t } = useTranslate()

  const [toolsOnScreen, setToolsOnScreen] = useState<Tool[]>([])
  const [toolsNotOnScreen, setToolsNotOnScreen] = useState<Tool[]>([])
  const [isEditMode, setIsEditMode] = useState(false)
  const [addBookmarkRequestInProgress, setAddBookmarkRequestInProgress] =
    useState<boolean>(false)
  const [updateToolsRequestInProgress, setUpdateToolsRequestInProgress] =
    useState<boolean>(false)
  const [newBookmark, setNewBookmark] = useState({ ...bookmarkInitialState })
  const [isAddBookmarkDialogOpened, setIsAddBookmarkDialogOpened] =
    useState(false)

  useEffect(() => {
    user?.tools && setToolsOnScreen(user.tools ?? [])
  }, [user?.tools])

  useEffect(() => {
    if (defaultTools?.length) {
      const newArr = defaultTools.filter(
        (defaultTool: Tool) =>
          !toolsOnScreen.find(
            (onScreenTool: Tool) => defaultTool.id === onScreenTool.id
          )
      )
      setToolsNotOnScreen(newArr)
    }
  }, [toolsOnScreen?.length, defaultTools?.length])

  const changeNewBookmark = (propName: string, value: string) => {
    setNewBookmark((old: Bookmark) => ({ ...old, [propName]: value }))
  }

  const newBookmarkIconUrl = useMemo(() => {
    return getToolIconByUrl(newBookmark.link)
  }, [newBookmark.link])

  const onSaveBookmarkClick = () => {
    if (newBookmark.link && checkIsWebUri(newBookmark.link)) {
      setAddBookmarkRequestInProgress(true)
      addBookmark(newBookmark)
        .then((res) => res.json())
        .then((res) => {
          if (res?.errors?.length) {
            setToast({
              message: t('field_error_tool_save_toast'),
              type: ToastTypeEnum.error
            })
          } else {
            setToast({
              message: t('field_success_tool_add_toast'),
              type: ToastTypeEnum.success
            })
            if (res?.data) {
              const savedBookmark = transferTool(res?.data)
              addToolToScreen(savedBookmark)
            }
          }
        })
        .finally(() => {
          setAddBookmarkRequestInProgress(false)
          setIsAddBookmarkDialogOpened(false)
          setNewBookmark({ ...bookmarkInitialState })
        })
    }
  }

  const removeToolFromScreen = (tool: Tool) => {
    if (tool?.type === ToolsTypes.custom && tool.id) {
      deleteBookmark(tool.id)
    }
    setToolsOnScreen(toolsOnScreen.filter((item: Tool) => item.id !== tool.id))
  }

  const addToolToScreen = (tool: Tool) => {
    setToolsOnScreen((old: Tool[]) => [...old, { ...tool }])
  }

  const onDoneButtonClick = () => {
    if (!isDeepEqual(user?.tools, toolsOnScreen)) {
      setUpdateToolsRequestInProgress(true)
      user?.id &&
        patchUserTools(user.id, toolsOnScreen)
          .then((res) => {
            if (res?.errors?.length) {
              setToast({
                message: t('field_error_update_tool_toast'),
                type: ToastTypeEnum.error
              })
            } else {
              setToast({
                message: t('field_success_update_tools_toast'),
                type: ToastTypeEnum.success
              })
            }
          })
          .finally(() => {
            setUpdateToolsRequestInProgress(false)
            setMe({
              ...user,
              tools: toolsOnScreen
            })
            setIsEditMode(false)
          })
    } else {
      setIsEditMode(false)
    }
  }

  const bookmarkUrlIsValid = useMemo(() => {
    return checkIsWebUri(newBookmark.link)
  }, [newBookmark.link])

  return (
    <Box
      css={{
        position: 'relative',
        zIndex: '$toolBar'
      }}
    >
      <Box
        css={{
          position: 'relative',
          zIndex: '2',
          d: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          gap: '$4 $6',
          w: '100%'
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {toolsOnScreen ? (
              <>
                {toolsOnScreen.length > 0 &&
                  toolsOnScreen.map((item: Tool) => (
                    <ToolCard
                      key={item.id}
                      name={item.name}
                      link={item.link}
                      iconUrl={getFaviconUrl(item.link)}
                      actionType={isEditMode ? 'remove' : undefined}
                      onActionClick={(e: React.SyntheticEvent<EventTarget>) => {
                        e.preventDefault()
                        removeToolFromScreen(item)
                      }}
                    />
                  ))}
                <EditToolCardButton
                  disabled={isEditMode}
                  onClick={() => setIsEditMode(true)}
                />
              </>
            ) : (
              <AddToolCardButton
                onClick={() => setIsEditMode(true)}
                translations={translations}
              />
            )}
          </>
        )}
      </Box>
      {isEditMode && (
        <>
          <Box
            css={{
              d: 'flex',
              position: 'absolute',
              bottom: '-24px',
              transform: 'translateY(100%)',
              background: '$cardBackgroundBlur',
              w: '100%',
              zIndex: '2',
              p: '$6',
              borderRadius: '$12',
              backdropFilter: 'blur(15px)',
              minHeight: '250px',
              '@md': {
                minHeight: '324px'
              }
            }}
          >
            <Box css={{ position: 'absolute' }}>
              <Heading5 css={{ color: '$white' }}>
                {toolsNotOnScreen.length
                  ? t('field_tools_all_tools_text')
                  : t('field_tools_add_tools_text')}
              </Heading5>
            </Box>
            <Box
              css={{
                d: 'flex',
                alignItems:
                  !toolsNotOnScreen.length || updateToolsRequestInProgress
                    ? 'center'
                    : 'flex-start',
                justifyContent:
                  !toolsNotOnScreen.length || updateToolsRequestInProgress
                    ? 'center'
                    : 'flex-start',
                pt:
                  !toolsNotOnScreen.length || updateToolsRequestInProgress
                    ? 0
                    : '$13',
                px: '$6',
                flexWrap: 'wrap',
                gap: '$6',
                w: '100%'
              }}
            >
              {updateToolsRequestInProgress ? (
                <Spinner />
              ) : (
                <>
                  <AddToolCardButton
                    onClick={() => setIsAddBookmarkDialogOpened(true)}
                    translations={translations}
                  />
                  {toolsNotOnScreen.length > 0 &&
                    toolsNotOnScreen.map((item: Tool) => (
                      <ToolCard
                        key={item.id}
                        name={item.name}
                        link={item.link}
                        actionType="add"
                        iconUrl={getFaviconUrl(item.link)}
                        onActionClick={(
                          e: React.SyntheticEvent<EventTarget>
                        ) => {
                          e.preventDefault()
                          addToolToScreen(item)
                        }}
                      />
                    ))}
                </>
              )}
            </Box>
          </Box>
          <Box
            css={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              d: 'flex',
              justifyContent: 'flex-end',
              backgroundColor: '$scrimBackgroundBlur',
              zIndex: '1',
              top: 0,
              left: 0
            }}
          >
            <Box>
              <Button
                css={{ m: '$3', mt: '$20' }}
                variant="secondary"
                onClick={onDoneButtonClick}
              >
                {t('field_done')}
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Dialog
        title={t('field_tools_add_link_text')}
        setOpenControlledDialog={setIsAddBookmarkDialogOpened}
        open={isAddBookmarkDialogOpened}
        contentProps={{ css: { '@md': { width: 550 } } }}
        innerContentCss={{ '@md': { pt: 0, pb: '$2' } }}
        footer={
          <Space
            css={{
              d: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setIsAddBookmarkDialogOpened(false)}
              css={{ mr: '$3' }}
            >
              {t('field_cancel')}
            </Button>
            <Button
              disabled={!bookmarkUrlIsValid || !newBookmark.name}
              onClick={onSaveBookmarkClick}
            >
              {t('field_save')}
            </Button>
          </Space>
        }
      >
        <Space
          my="MD"
          css={{
            d: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            gap: '$6'
          }}
        >
          {addBookmarkRequestInProgress ? (
            <Spinner />
          ) : (
            <>
              <Box css={{ position: 'relative' }}>
                <Space mb="SM">
                  <Body5>{t('field_tools_add_description_text')}</Body5>
                </Space>
                <Input
                  label="URL*"
                  placeholder={t('field_tools_url_input_plchldr')}
                  value={newBookmark.link}
                  onChange={(e) => changeNewBookmark('link', e.target.value)}
                  startEnhancer={
                    bookmarkUrlIsValid ? (
                      <Box
                        css={{
                          width: '24px',
                          height: '24px',
                          d: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Box
                          css={{ maxWidth: '100%', maxHeight: '100%' }}
                          as="img"
                          src={newBookmarkIconUrl || undefined}
                          alt="logo"
                        />
                      </Box>
                    ) : null
                  }
                />
              </Box>
              <Input
                label={`${t('field_tools_name_input_label')}*`}
                placeholder={t('field_tools_name_input_plchldr')}
                value={newBookmark.name}
                onChange={(e) => changeNewBookmark('name', e.target.value)}
              />
            </>
          )}
        </Space>
      </Dialog>
    </Box>
  )
}
