import { User } from './user'
import { useConfigStore } from '../state/config'
import useSWRImmutable from 'swr/immutable'
import { API_DOMAIN, fetcherFullRequest, NOTIFICATION_DOMAIN } from './common'
import { SearchEntityTypes } from './search'

const NOTIFICATIONS_URL = `https://${NOTIFICATION_DOMAIN}`

export type Notification = {
  id: number
  date: string
  unread: boolean
  type: NotificationType
  user: {
    nid?: number
    id?: string
    fullName?: string
    image?: string
  }
  entity: {
    id?: string
    title: string
    type: SearchEntityTypes
  }
  comment?: string
  commentId?: string
}

export enum NotificationType {
  All = '',
  Comment = 'COMMENT',
  Reply = 'REPLY',
  Like = 'LIKE',
  Idea = 'IDEA',
  IdeaStatus = 'IDEA_STATUS',
  IdeaAssigned = 'IDEA_ASSIGNED',
  IdeaReminder = 'IDEA_REMINDER'
}

export const sendMessage = (ws: WebSocket, message: unknown) => {
  ws.send(JSON.stringify(message))
}

export const register = (
  ws: WebSocket,
  userId: string,
  realUserName: string,
  user_mode: string,
  login: string
) => {
  ws.onopen = () =>
    sendMessage(ws, {
      id: 'register',
      userId: userId,
      realUserName: realUserName,
      mode: user_mode,
      login: login
    })
}

export function startNotificationSession(
  id: string,
  me: User,
  callBack: (ws: WebSocket) => void
) {
  const ws = new WebSocket(`wss://${NOTIFICATION_DOMAIN}/bus`)
  ws.addEventListener('error', (event) => {
    console.warn('WebSocket error: ', event)
    useConfigStore.getState().setPendingMessagesCount(0)
  })
  register(ws, id, `${me.firstName} ${me.lastName}`, 'master', me.email)
  callBack(ws)
}

export function resetPendingCount() {
  const { id, notificationBus } = useConfigStore.getState()
  if (notificationBus && id) {
    sendMessage(notificationBus, {
      id: 'pull',
      userId: id
    })
  }
}

export function changeNotificationReadStatus(
  id: number,
  user_id: number,
  aknowledge: boolean
) {
  return fetch(`${NOTIFICATIONS_URL}/relations/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({ user_id, aknowledge })
  })
}

export function useNotifications() {
  const { id } = useConfigStore.getState()

  const { data, error, mutate } = useSWRImmutable(
    `${NOTIFICATIONS_URL}/notification/byUserId/${id}`,
    fetcherFullRequest
  )

  return {
    mutate,
    notifications:
      data &&
      data?.result?.map((notification: TNotificationFromBackend) =>
        transferNotification(
          fixStringNullFromBackend(notification),
          data.usersData
        )
      ),
    isLoading: !error && !data,
    isError: error
  }
}

function fixStringNullFromBackend(
  notification: TNotificationFromBackend
): TNotificationRaw {
  if (notification?.relative_notification) {
    Object.entries(notification.relative_notification).forEach(
      ([key, value]) => {
        if (notification.relative_notification && key) {
          notification.relative_notification[key] =
            value === 'null' ? undefined : value
        }
      }
    )
  }

  return notification
}

type TNotificationFromBackend = TNotificationRaw & {
  relative_notification: Record<string, unknown>
}

type TNotificationRaw = {
  id: number
  aknowledge: string
  relative_notification?: {
    author_id: number
    notification_type: string
    content: string
    object_id: string
    created_at: string
    referer_id: string
    title: string
  }
}

type TNotificationUserData = {
  uuid: string
  user_picture: string
  field_first_name: string
  field_last_name: string
}

function transferNotification(
  notification: TNotificationRaw,
  usersData: Record<string, Partial<TNotificationUserData>> = {}
): Notification {
  const user = notification.relative_notification?.author_id
    ? usersData[notification.relative_notification.author_id] || {}
    : {}
  const type = getNotificationType(
    notification.relative_notification?.notification_type
  )

  return {
    id: notification.id,
    unread: !notification.aknowledge,
    comment: notification.relative_notification?.content,
    commentId:
      type === NotificationType.Comment
        ? notification.relative_notification?.object_id
        : undefined,
    date: notification.relative_notification?.created_at || '',
    type,
    user: {
      nid: notification.relative_notification?.author_id,
      id: user.uuid,
      image: user.user_picture && `${API_DOMAIN}${user.user_picture}`,
      fullName: `${user.field_first_name} ${user.field_last_name}`
    },
    entity: {
      id:
        notification.relative_notification?.referer_id ??
        notification.relative_notification?.object_id,
      type: getArticleType(
        notification.relative_notification?.notification_type
      ),
      title: notification.relative_notification?.title || ''
    }
  }
}

function getNotificationType(notificationType?: string) {
  switch (notificationType) {
    case 'IDEA':
      return NotificationType.Idea
    case 'IDEASTATUS':
      return NotificationType.IdeaStatus
    case 'IDEAREMINDER':
      return NotificationType.IdeaReminder
    case 'IDEAASSIGNED':
      return NotificationType.IdeaAssigned
    case 'LIKE':
      return NotificationType.Like
    case 'REPLY':
    case 'SPACEREPLY':
    case 'IDEAREPLY':
      return NotificationType.Reply
    default:
      return NotificationType.Comment
  }
}

function getArticleType(notificationType?: string) {
  switch (notificationType) {
    case 'SPACECOMMENT':
    case 'SPACEREPLY':
      return SearchEntityTypes.Spaces
    case 'IDEA':
    case 'IDEACOMMENT':
    case 'IDEAREPLY':
    case 'IDEASTATUS':
    case 'IDEAASSIGNED':
    case 'IDEAREMINDER':
      return SearchEntityTypes.Ideas
    default:
      return SearchEntityTypes.News
  }
}
