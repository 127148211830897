import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { styled } from '../../../../stitches.config'
import {
  Box,
  Container,
  ContainerPadding,
  Grid,
  Space,
  TextLink,
  Line,
  Heading4,
  Body4,
  Body5,
  MailIcon16,
  PhoneIcon16,
  MobilePhoneIcon16,
  Avatar,
  AvatarFallback,
  AvatarImage,
  LazyImage,
  Body7,
  Accordion,
  AccordionTrigger,
  AccordionContent,
  AccordionItem,
  PeopleIcon20,
  ClockIcon14
} from '@sefar/design-system'
import { useUser, User, useUserTeam } from '../../api'
import AvatarPlaceholder from '../../../assets/avatar-placeholder-blue.jpg'
import { useConfigStore } from '../../state/config'
import { useTranslate } from '../../hooks/useTranslate'
import { EditAvatarImageDialog } from './edit-avatar-image-dialog'
import { EditNicknameDialog } from './edit-nickname-dialog'
import { ContactHrDialog } from './contact-hr-dialog'
import WebexWidget from '../../components/WebexWidget'
import { utcToZonedTime, format } from 'date-fns-tz'

const NO_MAIL_MATCH = '@nomail'

export function UserCard({ user }: { user: User }) {
  return user ? (
    <Box
      as={Link}
      to={`/profile/${user.id}`}
      css={{
        textDecoration: 'none',
        d: 'flex',
        gap: '$2',
        color: '$neutral',
        overflow: 'hidden',
        '&:hover': {
          color: '$primaryBlue'
        }
      }}
    >
      <Space
        css={{
          size: 32,
          borderRadius: '$round',
          overflow: 'hidden',
          flexBasis: 32,
          flexShrink: 0
        }}
      >
        <LazyImage src={user?.image || AvatarPlaceholder} aspectRatio="1 / 1" />
      </Space>

      <Box css={{ d: 'flex', flexDirection: 'column' }}>
        <Body7 fontWeight="bold">
          {user.firstName}&nbsp;{user.lastName}
        </Body7>
        <Body7 css={{ color: '$neutralLighten30' }}>{user.jobTitle}</Body7>
      </Box>
    </Box>
  ) : (
    <></>
  )
}

const ContactLinkWrapper = styled(Box, {
  mb: '$6',
  d: 'flex',
  justifyContent: 'space-between',

  '@sm': {
    mb: '$4'
  }
})

export function UserDepartmentInfo({ user }: { user: User }) {
  return (
    <Box
      css={{ d: 'flex', flexWrap: 'wrap', color: '$neutralLighten30' }}
      data-cy="profileDepartmentInfo"
    >
      {user.department && (
        <>
          {user.department}
          &nbsp;
        </>
      )}
      {user.country && (
        <>
          <Box
            as="span"
            css={{
              position: 'relative',
              width: '5px',
              ml: '0.1875rem',
              mr: '0.3125rem',
              '&:after': {
                content: '.',
                position: 'absolute',
                top: '-20%',
                left: '0'
              }
            }}
          />
          {user.country}
        </>
      )}
    </Box>
  )
}

export function Profile() {
  const { me } = useConfigStore()
  const params = useParams()
  const { t } = useTranslate()
  const userId = params.userId ?? me?.id
  const isOwnProfile = userId === me?.id
  const { user: another } = useUser(isOwnProfile ? undefined : userId)
  const user = isOwnProfile ? me : another
  const { team } = useUserTeam(user?.email)

  const officeEmail = useMemo<string | undefined>(() => {
    if (
      user?.emailForBusiness &&
      !user.emailForBusiness.includes(NO_MAIL_MATCH)
    ) {
      return user.emailForBusiness
    }
    if (user?.email && !user.email.includes(NO_MAIL_MATCH)) {
      return user.email
    }

    return undefined
  }, [user?.emailForBusiness, user?.email])
  const officePhone = user?.officePhone || user?.phoneForBusiness
  const officeMobile = user?.officeMobile || user?.mobilePhoneForBusiness
  const officeTimezone = user?.timezone

  return (
    <Box css={{ height: '100%' }}>
      <Box
        css={{
          width: '100%',
          background: '$gradientBlue',
          height: '7.5rem',

          '@md': {
            height: '12rem'
          },
          '@lg': {
            height: '18.75rem'
          }
        }}
      >
        <ContainerPadding css={{ height: '100%' }}>
          <Container css={{ height: '100%' }}>
            <Box css={{ position: 'relative', height: '100%' }}>
              <Avatar
                variant="big"
                css={{
                  position: 'absolute',
                  left: 0,
                  bottom: '-40px',
                  overflow: 'initial',
                  '@lg': {
                    bottom: '-70px'
                  }
                }}
              >
                <AvatarImage
                  variant="noHover"
                  src={
                    (isOwnProfile ? me?.image : user?.image) ||
                    AvatarPlaceholder
                  }
                  alt="avatar"
                />
                <AvatarFallback css={{ borderRadius: '$round' }} delayMs={600}>
                  AM
                </AvatarFallback>

                {isOwnProfile && (
                  <EditAvatarImageDialog
                    user={me}
                    image={isOwnProfile ? me?.image : user?.image}
                  />
                )}
              </Avatar>
            </Box>
          </Container>
        </ContainerPadding>
      </Box>
      <ContainerPadding css={{ flex: 1 }}>
        <Container>
          <Grid css={{ mt: '$20' }}>
            <Box
              css={{
                gc: '1 / span 12',
                gcLg: '1 / span 4',
                '@lg': {
                  width: '300px'
                }
              }}
            >
              {user && (
                <Box
                  css={{
                    mt: '$1',
                    '@lg': {
                      mt: '$6'
                    }
                  }}
                >
                  <Heading4 data-cy="profileName">
                    {user.firstName && user.firstName}&nbsp;
                    {user.lastName && user.lastName}
                  </Heading4>
                  {user.nickname && (
                    <Heading4
                      data-cy="profileNickname"
                      css={{
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}
                    >
                      ({user.nickname})
                    </Heading4>
                  )}

                  {isOwnProfile && (
                    <EditNicknameDialog nickname={user.nickname} />
                  )}

                  <Body4 css={{ mb: '$1' }} data-cy="profileJobTitle">
                    {user.jobTitle && user.jobTitle}
                  </Body4>
                  <Body5>
                    <UserDepartmentInfo user={user}></UserDepartmentInfo>
                  </Body5>

                  <Space mb="LG" />

                  {isOwnProfile && <ContactHrDialog />}

                  <Line mt="XS" mb="MD" />

                  <ContactLinkWrapper>
                    <Box
                      css={{ d: 'flex', alignItems: 'center' }}
                      data-cy="profileContactLink"
                    >
                      <TextLink
                        href={
                          officePhone
                            ? `tel:${officePhone}`
                            : 'javascript:void(0)'
                        }
                        variant="neutral"
                        className={officePhone ? '' : 'disabled'}
                      >
                        <Box as="span" className="icon icon-left">
                          <PhoneIcon16 />
                        </Box>
                        {officePhone ?? t('field_office_phone_number')}
                      </TextLink>
                    </Box>
                    {isOwnProfile && (
                      <>
                        {/* {user.officePhone ? (
                          <TextLink>edit</TextLink>
                        ) : (
                          <TextLink>add</TextLink>
                        )} */}
                      </>
                    )}
                  </ContactLinkWrapper>
                  <ContactLinkWrapper>
                    <Box
                      css={{ d: 'flex', alignItems: 'center' }}
                      data-cy="profileOfficeContactLink"
                    >
                      <TextLink
                        href={
                          officeMobile
                            ? `tel:${officeMobile}`
                            : 'javascript:void(0)'
                        }
                        variant="neutral"
                        className={officeMobile ? '' : 'disabled'}
                      >
                        <Box as="span" className="icon icon-left">
                          <MobilePhoneIcon16 />
                        </Box>
                        {officeMobile ?? t('field_office_mobile_number')}
                      </TextLink>
                    </Box>
                    {isOwnProfile && (
                      <>
                        {/* {user.officeMobile ? (
                          <TextLink>edit</TextLink>
                        ) : (
                          <TextLink>add</TextLink>
                        )} */}
                      </>
                    )}
                  </ContactLinkWrapper>
                  <ContactLinkWrapper>
                    <Box
                      css={{ d: 'flex', alignItems: 'center' }}
                      data-cy="profileOfficeEmail"
                    >
                      <TextLink
                        href={
                          officeEmail
                            ? `mailto: ${officeEmail}`
                            : 'javascript:void(0)'
                        }
                        variant="neutral"
                        className={officeEmail ? '' : 'disabled'}
                      >
                        <Box as="span" className="icon icon-left">
                          <MailIcon16 />
                        </Box>
                        {officeEmail ?? t('field_office_email')}
                      </TextLink>
                    </Box>
                  </ContactLinkWrapper>
                  {/* {officeTimezone && (
                    <ContactLinkWrapper>
                      <Box
                        css={{ d: 'flex', alignItems: 'center' }}
                        data-cy="profileLocalTime"
                      >
                        <TextLink variant="neutral">
                          <Box as="span" className="icon icon-left">
                            <ClockIcon14 />
                          </Box>
                          {officeTimezone &&
                            format(
                              utcToZonedTime(new Date(), officeTimezone),
                              'HH:mm (z)'
                            )}
                        </TextLink>
                      </Box>
                    </ContactLinkWrapper>
                  )} */}
                  <WebexWidget email={officeEmail} />
                  <Line my="MD" />

                  <Body5 fontWeight="bold">{t('field_address')}</Body5>
                  {user.street && (
                    <Body5 data-cy="profileUserAddressStreet">
                      {user.street}
                    </Body5>
                  )}
                  <Body5 data-cy="profileUserAddressLocation">
                    {user.postCode && user.postCode}
                    &nbsp;
                    {user.location && user.location}
                  </Body5>
                  {user.country && (
                    <Body5 data-cy="profileUserAddressCountry">
                      {user.country}
                    </Body5>
                  )}
                  {user.region && (
                    <Body5 data-cy="profileUserAddressRegion">
                      {user.region}
                    </Body5>
                  )}

                  <Line my="MD" />

                  <Space mb="XS">
                    <Body5 fontWeight="bold" css={{ mb: '$1' }}>
                      {t('field_short_name')}
                    </Body5>
                    {user.shortName && (
                      <Body5 data-cy="profileUserShortName">
                        {user.shortName}
                      </Body5>
                    )}
                  </Space>

                  <Space mb="XS">
                    <Body5 fontWeight="bold" css={{ mb: '$1' }}>
                      {t('field_employee_id')}
                    </Body5>
                    {user.employeeid && (
                      <Body5 data-cy="profileEmployeeID">
                        {user.employeeid}
                      </Body5>
                    )}
                  </Space>

                  <Body5 fontWeight="bold" css={{ mb: '$1' }}>
                    {t('field_cost_center')}
                  </Body5>
                  {user.costCenter && (
                    <Body5 data-cy="profileUserCostCenter">
                      {user.costCenter}
                    </Body5>
                  )}

                  <Line my="MD" />
                </Box>
              )}
            </Box>

            <Box
              css={{
                gc: '1 / span 12',
                gcLg: '5 / span 12'
              }}
            >
              <Body4
                fontWeight="bold"
                css={{
                  mb: '$4',
                  mt: '$1',
                  '@lg': {
                    mt: '$6'
                  }
                }}
              >
                {t('field_team')}
              </Body4>

              {team && (
                <Box
                  css={{
                    boxShadow: '$card',
                    border: '1px $neutralLighten90 solid',
                    borderRadius: '$4'
                  }}
                >
                  <Accordion collapsible defaultValue="team">
                    <AccordionItem value="team">
                      <AccordionTrigger>
                        <Box
                          css={{
                            d: 'flex',
                            gap: '$2',
                            color: '$neutral'
                          }}
                        >
                          <Space
                            css={{
                              size: 40,
                              flexBasis: 40,
                              flexShrink: 0,
                              borderRadius: '$round',
                              overflow: 'hidden',
                              backgroundColor: '$primaryBlueLighten90',
                              color: '$primaryBlue',
                              d: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <PeopleIcon20 />
                          </Space>

                          {team?.users && (
                            <Box css={{ d: 'flex', flexDirection: 'column' }}>
                              <Body5 fontWeight="bold">
                                {team?.manager?.organization}
                              </Body5>
                              <Body7 css={{ color: '$neutralLighten30' }}>
                                {team?.users?.length + 1} {t('field_members')}
                              </Body7>
                            </Box>
                          )}
                        </Box>
                      </AccordionTrigger>
                      <AccordionContent>
                        <Body7 css={{ color: '$neutralLighten30', mb: '$4' }}>
                          {t('field_team_manager')}:
                        </Body7>
                        <UserCard user={team?.manager}></UserCard>
                        <Body7
                          css={{
                            color: '$neutralLighten30',
                            mb: '$4',
                            mt: '$8'
                          }}
                        >
                          {t('field_team_members')}:
                        </Body7>
                        <Box
                          css={{
                            d: 'grid',
                            gtc: 1,
                            rowGap: '$6',
                            columnGap: '$4',
                            width: '100%',
                            '@md': { gtc: 2 },
                            '@lg': { gtc: 3 }
                          }}
                        >
                          {team?.users?.map((user) => (
                            <UserCard user={user} key={user.id}></UserCard>
                          ))}
                        </Box>
                        {team?.team?.length ? (
                          <>
                            <Body7
                              css={{
                                color: '$neutralLighten30',
                                mb: '$4',
                                mt: '$8'
                              }}
                            >
                              {t('field_direct_reports', [
                                `${user.firstName} ${user.lastName}`
                              ])}
                              :
                            </Body7>
                            <Box
                              css={{
                                d: 'grid',
                                gtc: 1,
                                rowGap: '$6',
                                columnGap: '$4',
                                width: '100%',
                                '@md': { gtc: 2 },
                                '@lg': { gtc: 3 }
                              }}
                            >
                              {team?.team
                                ?.sort((a, b) =>
                                  a?.firstName.localeCompare(b?.firstName)
                                )
                                ?.map((user) => (
                                  <UserCard
                                    user={user}
                                    key={user.id}
                                  ></UserCard>
                                ))}
                            </Box>
                          </>
                        ) : null}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </Box>
              )}
            </Box>
          </Grid>
        </Container>
      </ContainerPadding>
    </Box>
  )
}
