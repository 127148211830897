import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams, useLocation } from 'react-router-dom'
import {
  useNewsArticle,
  NewsArticle,
  isRegularUser,
  isUserReviewer,
  isNotAllowedToEditArticleInReview,
  isUserAuthorOfArticle,
  MEDIA_TYPES,
  isTrashLanguage
} from '../../api'
import { useConfigStore } from '../../state/config'
import { NewsDetailsView } from './news-details-view'
import { NewsDetailsEdit } from './news-details-edit'
import { newsCreatePageUrl } from '../../app'
import { useCustomCompareEffect } from 'react-use'
import isEqual from 'lodash/isEqual'

export function NewsDetails() {
  const location = useLocation()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { me: user } = useConfigStore()

  const { contentLang: langConfig } = useConfigStore()
  const lang = params.lang || langConfig

  const { article: articleTranslated } = useNewsArticle(params.articleId!, lang)

  const { article: articleOriginal } = useNewsArticle(
    articleTranslated?.lang === lang &&
      articleTranslated?.langOrigin &&
      !isTrashLanguage(articleTranslated?.langOrigin) &&
      articleTranslated?.langOrigin !== articleTranslated?.lang
      ? params.articleId!
      : undefined,
    articleTranslated?.langOrigin
  )
  const [article, setArticle] = useState<NewsArticle>()
  const [isNew, setIsNew] = useState<boolean>(false)

  const [notAllowedToEdit, setNotAllowedToEdit] = useState<boolean>(false)
  const [mediaType, setMediaType] = useState<string>('image')

  useCustomCompareEffect(
    () => {
      setArticle(articleTranslated)
    },
    [articleTranslated?.id, articleTranslated?.viewers],
    (prevDeps, nextDeps) => isEqual(prevDeps[1], nextDeps[1])
  )

  useEffect(() => {
    if (article) {
      if (article.mediaType) {
        MEDIA_TYPES.video.includes(article.mediaType)
          ? setMediaType('video')
          : setMediaType('image')
      }
    }
  }, [article])

  useEffect(() => {
    setNotAllowedToEdit(
      !user ||
        isRegularUser(user) ||
        isNotAllowedToEditArticleInReview(user, article) ||
        (!isNew &&
          !isUserAuthorOfArticle(user, article) &&
          !isUserReviewer(user))
    )
  }, [user?.id, article, isNew])

  useEffect(() => {
    if (location.pathname === `/${newsCreatePageUrl}`) {
      setSearchParams({ editMode: 'true' })
    }
  }, [])

  useEffect(() => {
    if (!params?.articleId) {
      setIsNew(true)
    }
  }, [article?.status, params?.articleId])

  return (
    <>
      {!searchParams.get('editMode') || notAllowedToEdit ? (
        <NewsDetailsView
          article={article}
          articleOriginal={articleOriginal}
          articleTranslated={articleTranslated}
          lang={article?.lang}
          mediaType={mediaType}
          notAllowedToEdit={notAllowedToEdit}
          params={params}
          setArticle={setArticle}
          setSearchParams={setSearchParams}
        />
      ) : (
        <NewsDetailsEdit
          article={article}
          lang={lang}
          setArticle={setArticle}
          isNew={isNew}
        />
      )}
    </>
  )
}
