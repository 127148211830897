import { API_DOMAIN, fetcherFullRequest, init, Likes } from './common'
import useSWR from 'swr'
import { isIdeaManager, transferUser, User, USER_TYPE } from './user'
import useSWRImmutable from 'swr/immutable'
import { SEARCH_PAGE_LIMIT } from './search'
import { useConfigStore } from '../state/config'
import { useGet } from '../hooks/use-get'
import { getDateWithoutTime } from '../components'
import { ParsedQs } from 'qs'

export enum IdeaGroupStatus {
  New = 'New',
  Progress = 'In Progress',
  Completed = 'Completed',
  Rejected = 'Rejected'
}

export const IdeaLocs: { label: string; value: string[] }[] = [
  {
    label: 'Switzerland Heiden/Thal',
    value: ['SEF-CH']
  },
  {
    label: 'Romania Sefar',
    value: ['SEF-RO']
  },
  {
    label: 'Poland Sefar',
    value: ['SEF-PL']
  },
  {
    label: 'Sefar Mexico',
    value: ['SEF-MX']
  },
  {
    label: 'Switzerland Monosuisse',
    value: ['MOS-CH', 'MONO-CH']
  },
  {
    label: 'Germany Monosuisse',
    value: ['MOS-DE', 'MONO-DE']
  },
  {
    label: 'Mexico Monosuisse',
    value: ['MOS-MX', 'MONO-MX']
  },
  {
    label: 'Poland Monosuisse',
    value: ['MOS-PL', 'MONO-PL']
  },
  {
    label: 'Romania Monosuisse',
    value: ['MOS-RO', 'MONO-RO']
  },
  {
    label: 'Germany Sefar',
    value: ['SEF-DE']
  },
  {
    label: 'Canada',
    value: ['SBD-CA']
  },
  {
    label: 'Australia',
    value: ['SBD-AU']
  },
  {
    label: 'Brazil',
    value: ['SBD-BR']
  },
  {
    label: 'Great Britain',
    value: ['SEF-GB']
  },
  {
    label: 'India',
    value: ['SEF-IN']
  },
  {
    label: 'Italy',
    value: ['SEF-IT']
  },
  {
    label: 'Korea',
    value: ['SEF-KR']
  },
  {
    label: 'Morocco',
    value: ['SEF-MA']
  },
  {
    label: 'Netherlands',
    value: ['SEF-NL']
  },
  {
    label: 'Singapore',
    value: ['SEF-SG']
  },
  {
    label: 'Thailand',
    value: ['SEF-TH']
  },
  {
    label: 'Turkey',
    value: ['SEF-TR']
  },
  {
    label: 'United States',
    value: ['SEF-US']
  },
  {
    label: 'Malaysia',
    value: ['SFA-MY']
  },
  {
    label: 'South Africa',
    value: ['SFP-ZA']
  },
  {
    label: 'China',
    value: ['SFS-CN', 'STS-CN']
  },
  {
    label: 'France',
    value: ['SFY-FR']
  },
  {
    label: 'Hong Kong',
    value: ['SIN-HK']
  },
  {
    label: 'Spain',
    value: ['SMA-ES']
  },
  {
    label: 'Malaysia',
    value: ['SIN-HK']
  }
]

export const IdeaCountries = [
  'Global',
  'Switzerland',
  'Mexico',
  'Italy',
  'Spain',
  'Thailand',
  'Turkey',
  'United Kingdom',
  'USA',
  'Australia',
  'New Zealand',
  'Brazil',
  'Canada',
  'China',
  'Singapore',
  'France',
  'Germany',
  'India',
  'Romania',
  'Korea',
  'Malaysia',
  'Morocco',
  'Netherlands',
  'Poland',
  'Russia',
  'South Africa',
  'South Korea'
]

export const IdeaCountriesSuggestions: {
  [a: string]: (typeof IdeaCountries)[number]
} = {
  Australia: 'Australia',
  'Australia, New Zealand': 'Australia',
  Brasilien: 'Brazil',
  Brazil: 'Brazil',
  Canada: 'Canada',
  China: 'China',
  'China, Singapore': 'China',
  France: 'France',
  Frankreich: 'France',
  Germany: 'Germany',
  'Great Britain': 'United Kingdom',
  India: 'India',
  Indien: 'India',
  Italy: 'Italy',
  'Italy, Romania': 'Italy',
  Korea: 'South Korea',
  Malaysia: 'Malaysia',
  'Malaysia, Singapore': 'Malaysia',
  'Malaysia, Singapore, Switzerland': 'Malaysia',
  Mexico: 'Mexico',
  Mexiko: 'Mexico',
  Morocco: 'Morocco',
  Netherlands: 'Netherlands',
  Neuseeland: 'New Zealand',
  'New Zealand': 'New Zealand',
  Niederlande: 'Netherlands',
  Poland: 'Poland',
  Polen: 'Poland',
  'Republic of Korea (South Korea)': 'South Korea',
  Romania: 'Romania',
  'Romania, Switzerland': 'Romania',
  Rumänien: 'Romania',
  Russia: 'Russia',
  Schweiz: 'Switzerland',
  Singapore: 'Singapore',
  Singapur: 'Singapore',
  'South Africa': 'South Africa',
  'South Korea': 'South Korea',
  Spain: 'Spain',
  'Spain, Switzerland': 'Spain',
  Spanien: 'Spain',
  Switzerland: 'Switzerland',
  'Switzerland, Thailand': 'Switzerland',
  'Switzerland, United States': 'Switzerland',
  Thailand: 'Thailand',
  'The Netherlands': 'Netherlands',
  Turkey: 'Turkey',
  'United Kingdom': 'United Kingdom',
  'United States': 'USA',
  USA: 'USA',
  'Vereinigte Staaten von Amerika': 'USA',
  'Vereinigtes Königreich': 'United Kingdom'
}

export enum IdeaStatuses {
  New = 'New',
  'Not Considered' = 'Not Considered',
  'Review Pending' = 'Review Pending',
  'Approved' = 'Approved',
  'Rejected' = 'Rejected',
  'Implementation in Progress' = 'Implementation in Progress',
  'Implemented' = 'Implemented',
  'Rewarded' = 'Rewarded'
}

export const IdeaStatusesTranslations: Record<
  keyof typeof IdeaStatuses,
  string
> = {
  New: 'field_idea_status_new',
  'Not Considered': 'field_idea_status_not_considered',
  'Review Pending': 'field_idea_status_pending',
  Approved: 'field_idea_status_approved',
  Rejected: 'field_idea_status_rejected',
  'Implementation in Progress': 'field_idea_status_in_progress',
  Implemented: 'field_idea_status_implemented',
  Rewarded: 'field_idea_status_rewarded'
}

export const IdeaStatusesByGroups = {
  [IdeaGroupStatus.New]: {
    [IdeaStatuses.New]: IdeaStatuses.New
  },
  [IdeaGroupStatus.Progress]: {
    [IdeaStatuses['Review Pending']]: IdeaStatuses['Review Pending'],
    [IdeaStatuses['Approved']]: IdeaStatuses['Approved'],
    [IdeaStatuses['Implementation in Progress']]:
      IdeaStatuses['Implementation in Progress']
  },
  [IdeaGroupStatus.Completed]: {
    [IdeaStatuses['Implemented']]: IdeaStatuses['Implemented'],
    [IdeaStatuses['Rewarded']]: IdeaStatuses['Rewarded']
  },
  [IdeaGroupStatus.Rejected]: {
    [IdeaStatuses['Rejected']]: IdeaStatuses['Rejected'],
    [IdeaStatuses['Not Considered']]: IdeaStatuses['Not Considered']
  }
}

export type Idea = {
  id: string
  drupalInternalNid: string
  lang: string
  title: string
  status: IdeaStatuses
  date: string
  dateOfRealization: string
  author: User
  implementer: User | undefined
  anonymous: boolean
  aimsBenefits: string
  improvement: string
  problem: string
  involved: string
  country: string
  location?: string[]
  categories: string[]
  feedback: string
  commentCount: number
  expectedSavings?: string
  expectedTurnover?: string
  implementationCosts?: number
  expectedImpact?: string[]
} & { likes: Likes }

export const IDEA_TYPE = 'node--ideas'

export function useIdea<T = Idea>(id: string, lang: string) {
  const langQuery = lang === 'en' ? '' : `/${lang}`
  const { data, error, mutate } = useGet(
    id
      ? [
          `${API_DOMAIN}${langQuery}/jsonapi/node/ideas/${id}?include=uid,uid.user_picture`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )

  return {
    mutate,
    idea: data ? transferIdea(data?.data, data?.included) : data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useIdeas(
  query: Record<
    string,
    string | string[] | undefined | number | boolean | ParsedQs | ParsedQs[]
  >
) {
  const { contentLang, me } = useConfigStore.getState()
  const ideaManager = isIdeaManager(me)
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  let {
    search,
    sort,
    mostLiked,
    page = 0,
    type = ideaManager ? IdeaStatuses.New : IdeaGroupStatus.Progress,
    year,
    category,
    status,
    my = 0,
    author,
    createdAfter,
    limit,
    location
  } = query
  const { id } = useConfigStore.getState()
  const sortQuery = mostLiked
    ? '&sort=-field_ideas_likes'
    : `&sort=${sort ? '' : '-'}created`
  const searchQuery = search ? `&filter[fulltext]=${search}` : ''
  const yearQuery = year ? `&filter[field_ideas_year]=${year}` : ''
  const categoryQuery = category
    ? `&filter[field_ideas_category]=${category}`
    : ''
  const meQuery = my ? `&filter[field_ideas_implementer]=${id}` : ''
  const authorQuery = author ? `&filter[uid]=${author}` : ''
  const queryLimit = typeof limit === 'number' ? limit : SEARCH_PAGE_LIMIT
  const createAfterFilter = createdAfter
    ? `&filter[recent][condition][path]=created&filter[recent][condition][operator]=>=&filter[recent][condition][value]=${createdAfter}`
    : ''
  let statusFilters: string
  if (type === IdeaGroupStatus.Rejected) {
    statusFilters = `&filter[field_ideas_group_status]=${IdeaGroupStatus.Progress}`
    if (!status) {
      statusFilters += `&filter[or-group][group][conjunction]=OR&filter[rejected-filter][condition][path]=field_ideas_status&filter[rejected-filter][condition][operator]==&filter[rejected-filter][condition][value]=${IdeaStatuses.Rejected}&filter[rejected-filter][condition][memberOf]=or-group&filter[not-considered-filter][condition][path]=field_ideas_status&filter[not-considered-filter][condition][operator]==&filter[not-considered-filter][condition][value]=${IdeaStatuses['Not Considered']}&filter[not-considered-filter][condition][memberOf]=or-group`
    }
  } else if (
    (type === IdeaGroupStatus.New && !ideaManager) ||
    type === IdeaGroupStatus.Progress
  ) {
    statusFilters = `&filter[field_ideas_group_status]=${IdeaGroupStatus.Progress}&filter[field-ideas-status][condition][path]=field_ideas_status&filter[field-ideas-status][condition][operator]=<>&filter[field-ideas-status][condition][value]=${IdeaStatuses.Rejected}`
  } else {
    statusFilters = `&filter[field_ideas_group_status]=${type}`
  }
  if (status) statusFilters += `&filter[field_ideas_status]=${status}`

  let locationFilter = ''
  if (typeof location === 'string') {
    locationFilter = `&filter[field_ideas_location]=${location}`
  } else if (location && Array.isArray(location)) {
    location.forEach((loc) => {
      locationFilter += `&filter[field_ideas_location]=${loc}`
    })
  }

  const { data, error, mutate } = useSWR(
    [
      `${API_DOMAIN}${langQuery}/jsonapi/index/sefardev?filter[type]=ideas${statusFilters}${yearQuery}${categoryQuery}${meQuery}${authorQuery}${createAfterFilter}${locationFilter}&page[offset]=${
        +page * queryLimit
      }&page[limit]=${queryLimit}${sortQuery}&include=uid,uid.user_picture,field_ideas_implementer,field_ideas_implementer.user_picture${searchQuery}`,
      init
    ],
    fetcherFullRequest
  )

  return {
    mutate,
    ideas:
      data && data?.data?.map((idea: any) => transferIdea(idea, data.included)),
    count: data?.meta?.count || 0,
    isLoading: !error && !data,
    isError: error
  }
}

export function useIdeasV2(
  query: Record<string, string[] | string | undefined | number | boolean | null>
) {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  let {
    page = 0,
    type,
    year,
    category,
    status,
    createdAfter,
    limit,
    department,
    country,
    location
  } = query
  const yearQuery = year ? `&filter[field_ideas_year]=${year}` : ''
  const categoryQuery = category
    ? `&filter[field_ideas_category]=${category}`
    : ''
  const queryLimit = typeof limit === 'number' ? limit : SEARCH_PAGE_LIMIT
  const createAfterFilter = createdAfter
    ? `&filter[recent][condition][path]=created&filter[recent][condition][operator]=>=&filter[recent][condition][value]=${createdAfter}`
    : ''
  let statusFilters: string = ''
  if (type === IdeaGroupStatus.Rejected) {
    statusFilters = `&filter[field_ideas_group_status]=${IdeaGroupStatus.Progress}`
    if (!status) {
      statusFilters += `&filter[or-group][group][conjunction]=OR&filter[rejected-filter][condition][path]=field_ideas_status&filter[rejected-filter][condition][operator]==&filter[rejected-filter][condition][value]=${IdeaStatuses.Rejected}&filter[rejected-filter][condition][memberOf]=or-group&filter[not-considered-filter][condition][path]=field_ideas_status&filter[not-considered-filter][condition][operator]==&filter[not-considered-filter][condition][value]=${IdeaStatuses['Not Considered']}&filter[not-considered-filter][condition][memberOf]=or-group`
    }
  }
  if (type === IdeaGroupStatus.Progress || type === IdeaGroupStatus.Completed) {
    statusFilters = `&filter[field_ideas_group_status]=${IdeaGroupStatus.Progress}`
  }

  let departmentFilter = ''
  if (department) {
    departmentFilter = `&filter[field_author_department_1]=${department}`
  }

  let countryFilter = ''
  if (country) {
    countryFilter = `&filter[field_ideas_country]=${country}`
  }

  let locationFilter = ''
  if (typeof location === 'string') {
    locationFilter = `&filter[field_ideas_location]=${location}`
  } else if (location && Array.isArray(location)) {
    location.forEach((loc) => {
      locationFilter += `&filter[field_ideas_location]=${loc}`
    })
  }

  const { data, error, mutate } = useSWR(
    [
      `${API_DOMAIN}${langQuery}/jsonapi/index/sefardev?filter[type]=ideas${statusFilters}${yearQuery}${categoryQuery}${createAfterFilter}${departmentFilter}${countryFilter}${locationFilter}&page[offset]=${
        +page * queryLimit
      }&page[limit]=${queryLimit}&include=uid,uid.user_picture,field_ideas_implementer,field_ideas_implementer.user_picture`,
      init
    ],
    fetcherFullRequest
  )

  return {
    mutate,
    ideas:
      data && data?.data?.map((idea: any) => transferIdea(idea, data.included)),
    count: data?.meta?.count || 0,
    isLoading: !error && !data,
    isError: error
  }
}

export function createOrPatchIdea(data: any, id?: string) {
  const { field_ideas_implementer, ...attributes } = data
  return id
    ? patchIdea({
        id,
        attributes,
        ...(data?.field_ideas_implementer
          ? {
              relationships: {
                field_ideas_implementer: {
                  data: { id: field_ideas_implementer?.id, type: USER_TYPE }
                }
              }
            }
          : {})
      })
    : fetch(`${API_DOMAIN}/jsonapi/node/ideas`, {
        ...init,
        headers: {
          ...init.headers,
          'Content-Type': 'application/vnd.api+json'
        },
        method: 'POST',
        body: JSON.stringify({
          data: field_ideas_implementer
            ? {
                type: IDEA_TYPE,
                attributes: {
                  field_ideas_status: IdeaStatuses['Review Pending'],
                  ...attributes
                },
                relationships: {
                  field_ideas_implementer: {
                    data: { id: field_ideas_implementer?.id, type: USER_TYPE }
                  }
                }
              }
            : {
                type: IDEA_TYPE,
                attributes: {
                  field_ideas_status: IdeaStatuses.New,
                  ...data
                }
              }
        })
      }).then((res) => res.json())
}

export function patchIdea(data: any) {
  const lang = data.attributes?.langcode || 'en'
  const langQuery = lang === 'en' ? '' : `/${lang}`
  return fetch(`${API_DOMAIN}${langQuery}/jsonapi/node/ideas/${data.id}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: IDEA_TYPE,
        ...data
      }
    })
  }).then((res) => res.json())
}

export function assignImplementor(
  id: string,
  userId: string,
  langcode: string
) {
  return patchIdea({
    id,
    attributes: {
      langcode,
      field_ideas_status: IdeaStatuses['Review Pending']
    },
    relationships: {
      field_ideas_implementer: { data: { id: userId, type: USER_TYPE } }
    }
  })
}

export function changeIdeaStatus(
  id: string,
  status: string,
  langcode: string,
  feedback?: string,
  dateOfRealization?: Date
) {
  const feedbackData = feedback ? { field_ideas_feedback: feedback } : {}
  const dateOfRealizationData = dateOfRealization
    ? { field_date_of_realization: getDateWithoutTime(dateOfRealization) }
    : {}
  return patchIdea({
    id,
    attributes: {
      langcode,
      field_ideas_status: status,
      ...feedbackData,
      ...dateOfRealizationData
    }
  })
}

export function changeIdeaRealisationDate(
  id: string,
  langcode: string,
  dateOfRealization?: Date
) {
  const dateOfRealizationData = dateOfRealization
    ? { field_date_of_realization: getDateWithoutTime(dateOfRealization) }
    : {}
  return patchIdea({
    id,
    attributes: {
      langcode,
      ...dateOfRealizationData
    }
  })
}

export function changeIdeaFeedback(
  id: string,
  langcode: string,
  feedback: string
) {
  return patchIdea({
    id,
    attributes: {
      langcode,
      field_ideas_feedback: feedback
    }
  })
}

export function setIdeaNotConsidered(
  id: string,
  feedback: string,
  langcode: string
) {
  return changeIdeaStatus(
    id,
    IdeaStatuses['Not Considered'],
    langcode,
    feedback
  )
}

export function transferIdea(
  { attributes, relationships, id }: any,
  included: any[] = []
): Idea {
  if (!attributes) {
    return { author: {} as User } as Idea
  }
  return {
    id,
    drupalInternalNid: attributes.drupal_internal__nid,
    lang: attributes.langcode,
    title: attributes.title,
    status: attributes.field_ideas_status,
    date: attributes.created,
    anonymous: !attributes.field_ideas_privacy,
    feedback: attributes.field_ideas_feedback,
    dateOfRealization: attributes.field_date_of_realization,
    implementer: transferUser(
      included.find(
        (entity) =>
          relationships.field_ideas_implementer?.data?.id === entity.id
      ),
      included
    ),
    author: transferUser(
      included.find((entity) => relationships.uid.data.id === entity.id),
      included
    ),
    expectedTurnover: attributes.field_ideas_expected_savings,
    expectedSavings: attributes.field_ideas_expected_increase,
    implementationCosts: attributes.field_ideas_implementation_costs,
    expectedImpact: attributes.field_ideas_impact,
    aimsBenefits: attributes.field_ideas_aims_benefits,
    improvement: attributes.field_ideas_improvement,
    problem: attributes.field_ideas_problem,
    involved: attributes.field_ideas_involved_implements,
    categories: attributes.field_ideas_category,
    country: attributes.field_ideas_country,
    location: attributes.field_ideas_location,
    commentCount: attributes?.comment?.comment_count ?? 0,
    likes: {
      likes: attributes?.field_like?.likes,
      dislikes: attributes?.field_like?.dislikes,
      clickedBy: attributes?.field_like?.clicked_by
    }
  }
}

export function useIdeaCategories() {
  const { data, error } = useSWRImmutable(
    [`${API_DOMAIN}/ideas/categories`, init],
    fetcherFullRequest
  )
  return {
    categories: data,
    isLoading: !error && !data,
    isError: error
  }
}
