import {
  Body5,
  Box,
  Container,
  ContainerPadding,
  Space
} from '@sefar/design-system'
import React from 'react'
import { ChannelTypeEnum, Language, useLanguages, User } from '../../api'
import { NewsChannelDialog } from './news-channel-dialog'
import { NewsCategoriesDialog } from './news-categories-dialog'
import { LanguageDialog } from './new-language-dialog'
import { NewsAuthorDialog } from './news-author-dialog'

export type NewsArticleSettings = {
  langcode: string
  field_location: string[]
  field_news_category: string[]
  field_news_must_read?: boolean
  channelType: ChannelTypeEnum
  field_news_author?: User
}

export type ArticleSettingsTools = Partial<
  Record<keyof NewsArticleSettings, boolean>
>

export function NewsDetailsSettings({
  isNew,
  settings,
  onSettingsUpdate,
  tools = {
    langcode: true,
    field_location: true,
    field_news_category: true,
    channelType: true,
    field_news_author: true
  }
}: {
  isNew: boolean
  settings: NewsArticleSettings
  onSettingsUpdate: (update: Partial<NewsArticleSettings>) => void
  tools?: ArticleSettingsTools
}) {
  const { languages } = useLanguages()

  return (
    <Box
      css={{
        width: '100%',
        backgroundColor: '$neutralLighten97',
        mb: '$footerHeightMd',
        '@sm': {
          mb: '$footerHeightSm'
        }
      }}
    >
      <Container>
        <ContainerPadding>
          <Space
            css={{
              d: 'grid',
              gtc: 1,
              rowGap: '$4',
              columnGap: '$6',
              width: '100%',
              '@md': { gtc: 2 },
              '@lg': { gtc: 4 }
            }}
            px="XS"
            py="MD"
          >
            {tools.langcode && (
              <Box
                css={{
                  d: 'flex',
                  flexDirection: 'column',
                  '@sm': {
                    borderBottom: '1px $neutralLighten80 solid',
                    pb: '$4'
                  },
                  '@md': { borderRight: '1px $neutralLighten80 solid' }
                }}
              >
                <Box
                  css={{
                    '@sm': {
                      d: 'flex',
                      justifyContent: 'space-between',
                      mb: '$2'
                    }
                  }}
                >
                  <Body5 fontWeight="bold" css={{ '@md': { mb: '$4' } }}>
                    Language*
                  </Body5>
                  {isNew && (
                    <LanguageDialog
                      onUpdate={(langcode) => onSettingsUpdate({ langcode })}
                      selectedLang={settings.langcode}
                    />
                  )}
                </Box>

                {languages && (
                  <Body5 css={{ color: '$neutralLighten30' }}>
                    {
                      languages?.find(
                        ({ id }: Language) => id === settings.langcode
                      )?.labelEng
                    }
                  </Body5>
                )}
              </Box>
            )}

            {tools.channelType && (
              <Box
                css={{
                  d: 'flex',
                  flexDirection: 'column',
                  '@sm': {
                    borderBottom: '1px $neutralLighten80 solid',
                    pb: '$4'
                  },
                  '@md': { borderRight: '1px $neutralLighten80 solid' }
                }}
              >
                <Box
                  css={{
                    '@sm': {
                      d: 'flex',
                      justifyContent: 'space-between',
                      mb: '$2'
                    }
                  }}
                >
                  <Body5 fontWeight="bold" css={{ '@md': { mb: '$4' } }}>
                    News Channel*
                  </Body5>
                  <NewsChannelDialog
                    selectedCountries={settings.field_location}
                    isMustRead={settings.field_news_must_read}
                    selectedChannelType={settings.channelType}
                    onUpdate={(patch) => onSettingsUpdate(patch)}
                  />
                </Box>

                <Body5 css={{ color: '$neutralLighten30' }}>
                  {settings.channelType === ChannelTypeEnum.global
                    ? 'Global'
                    : settings.field_location?.join(', ')}
                </Body5>
              </Box>
            )}

            {tools.field_news_author && (
              <Box
                css={{
                  d: 'flex',
                  flexDirection: 'column',
                  '@sm': {
                    borderBottom: '1px $neutralLighten80 solid',
                    pb: '$4'
                  },
                  '@md': { borderRight: '1px $neutralLighten80 solid' }
                }}
              >
                <Box
                  css={{
                    '@sm': {
                      d: 'flex',
                      justifyContent: 'space-between',
                      mb: '$2'
                    }
                  }}
                >
                  <Body5 fontWeight="bold" css={{ '@md': { mb: '$4' } }}>
                    Author*
                  </Body5>
                  <NewsAuthorDialog
                    onUpdate={(field_news_author: User) =>
                      onSettingsUpdate({ field_news_author })
                    }
                  />
                </Box>

                <Body5 css={{ color: '$neutralLighten30' }}>
                  {settings.field_news_author?.firstName}{' '}
                  {settings.field_news_author?.lastName}
                </Body5>
              </Box>
            )}

            {tools.field_news_category && (
              <Box
                css={{
                  d: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box
                  css={{
                    '@sm': {
                      d: 'flex',
                      justifyContent: 'space-between',
                      mb: '$2'
                    }
                  }}
                >
                  <Body5 fontWeight="bold" css={{ '@md': { mb: '$4' } }}>
                    Categories
                  </Body5>
                  <NewsCategoriesDialog
                    selectedCategories={settings.field_news_category}
                    onUpdate={(field_news_category) =>
                      onSettingsUpdate({ field_news_category })
                    }
                  />
                </Box>

                <Body5 css={{ color: '$neutralLighten30' }}>
                  {settings.field_news_category?.join(', ')}
                </Body5>
              </Box>
            )}
          </Space>
        </ContainerPadding>
      </Container>
    </Box>
  )
}
