import React from 'react'
import { Box } from '../utils'
import { Body6, Body7 } from '../typography'
import { RemoveActionButton, AddActionButton } from '../add-remove-buttons'
import { EditIcon20, Add20, Bookmark32 } from '../icons'
import Bookmark32Svg from '../icons/bookmark-32.svg'

export function ToolCardDefault({
  name,
  link,
  disabled = false,
  wrapperCss = {},
  iconWrapperCss = {},
  actionType,
  iconUrl,
  onActionClick,
  onClick,
  className = '',
  children
}: {
  name?: string
  link?: string
  disabled?: boolean
  wrapperCss?: Record<string, string>
  iconWrapperCss?: Record<string, string>
  actionType?: string
  iconUrl?: string
  onActionClick?: (e: React.SyntheticEvent<EventTarget>) => void
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  className?: string
  children?: React.ReactNode
}) {
  return (
    <Box
      as={!actionType && link ? 'a' : 'button'}
      href={!actionType && link ? link : undefined}
      target="_blank"
      className={`${disabled ? 'disabled' : ''} ${
        !actionType && link ? 'link' : ''
      } ${className}`}
      onClick={onClick}
      css={{
        all: 'unset',
        textDecoration: 'none',
        position: 'relative',
        color: '$white',
        width: '64px',
        '&.link': {
          cursor: 'pointer'
        },
        '&.disabled': {
          cursor: 'auto',
          '& .icon-wrapper': {
            '& svg': {
              fill: '$neutralLighten60',
              path: {
                fill: '$neutralLighten60'
              }
            }
          },
          '&:hover, &.__hover': {
            '& .icon-wrapper': {
              boxShadow: 'none'
            }
          }
        },
        '&:hover:not(.disabled), &.__hover:not(.disabled)': {
          '& .icon-wrapper': {
            boxShadow: '$toolsHover'
          }
        },
        ...wrapperCss
      }}
    >
      {actionType && (
        <Box
          css={{
            position: 'absolute',
            left: 0,
            top: 0,
            transform: 'translateX(-50%) translateY(-50%)'
          }}
        >
          {actionType === 'add' ? (
            <AddActionButton onClick={onActionClick} />
          ) : actionType === 'remove' ? (
            <RemoveActionButton onClick={onActionClick} />
          ) : (
            <></>
          )}
        </Box>
      )}

      <Box
        className="icon-wrapper"
        css={{
          w: '100%',
          height: '64px',
          d: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '$white',
          borderRadius: '10px',
          cursor: actionType === 'add' ? 'pointer' : 'auto',
          '& object': {
            maxWidth: '32px',
            maxHeight: '32px'
          },
          ...iconWrapperCss
        }}
        onClick={actionType === 'add' ? onActionClick : undefined}
      >
        {children ? (
          <>{children}</>
        ) : (
          <>
            {iconUrl ? (
              <object data={iconUrl} type="image/png">
                <img src={Bookmark32Svg} alt="Logo" />
              </object>
            ) : (
              <Body6 css={{ color: '$neutralLighten60' }}>
                <Bookmark32 />
              </Body6>
            )}
          </>
        )}
      </Box>
      {name && (
        <Body7
          css={{
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            my: '$2'
          }}
        >
          {name}
        </Body7>
      )}
    </Box>
  )
}

export const ToolCard = React.memo(ToolCardDefault)

export function ToolCardButton({
  icon,
  text,
  disabled,
  onClick,
  className = ''
}: {
  icon: React.ReactNode
  text?: string
  disabled?: boolean
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  className?: string
}) {
  return (
    <ToolCard
      name={text}
      wrapperCss={{
        cursor: 'pointer'
      }}
      iconWrapperCss={{
        background: '$neutralLighten30'
      }}
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {icon}
    </ToolCard>
  )
}

export function EditToolCardButton({
  disabled,
  onClick
}: {
  disabled?: boolean
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
}) {
  return (
    <ToolCardButton
      disabled={disabled}
      icon={<EditIcon20 />}
      onClick={onClick}
    />
  )
}

export function AddToolCardButton({
  onClick,
  translations
}: {
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void
  translations?: Record<string, string>
}) {
  return (
    <ToolCardButton
      icon={<Add20 />}
      text={translations?.field_tools_add_link_text || 'Add link'}
      onClick={onClick}
    />
  )
}
