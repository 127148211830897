// import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './app/app'
import {
  SpinnerContextProvider,
  ToastContextProvider
} from '@sefar/design-system'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ToastContextProvider>
    <SpinnerContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SpinnerContextProvider>
  </ToastContextProvider>
)
