import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@sefar/design-system'
import { usePurchaseRequisition } from '../api'
import { BottomActionsBar } from '../create/components/bottom-actions-bar'
import { Heading } from '../create/components/heading'
import { StepsProgress } from '../create/components/steps-progress'
import { CURRENCY } from '../create/consts'
import { useOrderState } from '../create/hooks/use-order-state'
import { useOrderSteps } from '../create/hooks/use-order-steps'
import { PurchaseRequisitionOrderItem } from '../create/types'

export const CreatePurchaseRequisitionEdit = () => {
  const { hasNextStep, StepComponent } = useOrderSteps()
  const { setDetails, setItems } = useOrderState()
  const { id } = useParams()
  const { data } = usePurchaseRequisition(id)
  useEffect(() => {
    if (data) {
      const { purchaseRequisitionItems, ...details } = data
      const preparedItems: PurchaseRequisitionOrderItem[] =
        purchaseRequisitionItems?.map((item: any) => ({
          id: item.id,
          code: item?.product?.code,
          productId: item.productId,
          itemDescription: item.itemDescription,
          currency: item?.currency || CURRENCY,
          price: item.price,
          supplierItemId: item.supplierItemId,
          supplier: item.supplier,
          unit: item.unit,
          quantity: item.quantity,
          requiredDeliveryDate: item.requiredDeliveryDate
        }))
      setItems(preparedItems)
      setDetails(details)
    }
  }, [data])
  return (
    <Box
      css={{
        d: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pb: '$40'
      }}
    >
      <Heading />
      <StepsProgress />
      <StepComponent />
      {hasNextStep && <BottomActionsBar />}
    </Box>
  )
}
