import {
  useToastContext,
  Body4,
  Body6,
  Box,
  Document24,
  ToastTypeEnum
} from '@sefar/design-system'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Media } from '../types'
import { purchaseApi, usePurchaseRequisitionAttachments } from '../../api'
import { useTranslate } from '../../../../hooks/useTranslate'

const acceptedFileTypes = {
  'image/bmp': ['.bmp'],
  'text/csv': ['.csv'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx'
  ],
  'application/acad': ['.dwg'],
  'image/gif': ['.gif'],
  'image/g3fax': ['.g3f'],
  'image/jpeg': ['.jfif', '.jpe', '.jpeg', '.jpg'],
  'application/octet-stream': ['.msg'],
  'application/vnd.ms-outlook': ['.msg'],
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx'
  ],
  'image/tiff': ['.tif', '.tiff'],
  'text/plain': ['.txt'],
  'application/vnd.ms-excel': ['.xla', '.xlc', '.xll', '.xlm', '.xls'],
  'application/excel': ['.xla', '.xlc', '.xld', '.xll', '.xlm', '.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx'
  ],
  'application/xml': ['.xml'],
  'application/zip': ['.zip'],
  'x-zip-compressed': ['.zip'],
  'application/dxf': ['.dxf'],
  'application/STEP': ['.step']
}

const hintAllowedFileTypes = [
  '.bmp',
  '.csv',
  '.doc',
  '.docx',
  '.dwg',
  '.jpeg',
  '.jpg',
  '.msg',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.tif',
  '.tiff',
  '.txt',
  '.xlsx',
  '.xml',
  '.zip',
  '.dxf',
  '.step'
]

type Props = {
  attachments?: string[]
  onAttachmentsUpload: (attachments: string[]) => void
}

const Attachments = ({ attachments, onAttachmentsUpload }: Props) => {
  const setToast = useToastContext()
  const { t } = useTranslate()
  const { data: files } = usePurchaseRequisitionAttachments(attachments)
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        const formData = new FormData()
        for (const file of acceptedFiles) {
          formData.append('files', file, file.name)
        }
        formData.append('prefix', 'purchase-req-attachments')
        formData.append('postfix', new Date().toISOString())
        formData.append('tag', 'DOC')
        const { data } = await purchaseApi.uploadAttachments(formData)
        if (data?.length) {
          const attachments = []
          for (const attachment of data) {
            if (attachment.id) {
              attachments.push(attachment.id)
            }
          }
          onAttachmentsUpload(attachments)
        }
      } catch (e) {
        setToast({
          message: t('field_error_common_toast_text'),
          type: ToastTypeEnum.error
        })
      }
    },
    [onAttachmentsUpload]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes
  })
  return (
    <Box
      {...getRootProps()}
      css={{
        minHeight: 252,
        border: isDragActive ? '1px dashed $primaryBlue' : '1px dashed #99A3AB',
        backgroundColor: isDragActive
          ? '$neutralLighten90'
          : '$neutralLighten97',
        borderRadius: '$4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: '$4'
      }}
    >
      <input {...getInputProps()} />
      {files && files.length ? (
        <Box
          css={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
        >
          {files.map((file: Media) => (
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: '$3'
              }}
              key={file.file_id}
            >
              <Box css={{ position: 'relative' }}>
                <Document24 />
              </Box>
              <Body4
                css={{
                  color: '$neutral',
                  mt: '$3',
                  textAlign: 'center',
                  w: 200,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                fontWeight="bold"
              >
                {file.file_name}
              </Body4>
            </Box>
          ))}
        </Box>
      ) : (
        <Body4
          css={{ color: '$neutral', textAlign: 'center' }}
          fontWeight="bold"
        >
          Drag & drop files
          <br />
          <Body6
            css={{
              mt: '$1',
              maxWidth: '28rem',
              fontStyle: 'italic',
              fontWeight: '$normal'
            }}
          >
            Allowed file types: {hintAllowedFileTypes.join(', ')}
          </Body6>
        </Body4>
      )}
    </Box>
  )
}

export default Attachments
