import { ChannelTypeEnum, useCountries } from '../../api'
import React, { useEffect, useState } from 'react'
import {
  Body5,
  Body6,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  RadioGroup,
  RadioItem,
  Space,
  TextLink
} from '@sefar/design-system'
import { NewsArticleSettings } from './news-details-settings'
import { MustReadBadge } from '../../components'
import { useTranslate } from '../../hooks/useTranslate'

export function NewsChannelDialog({
  selectedCountries,
  isMustRead = false,
  selectedChannelType,
  onUpdate
}: {
  selectedCountries: string[]
  isMustRead?: boolean
  selectedChannelType: ChannelTypeEnum
  onUpdate: (patch: Partial<NewsArticleSettings>) => void
}) {
  const { t } = useTranslate()
  const [dialogCountries, setDialogCountries] = useState<string[]>(
    selectedCountries || []
  )
  const [dialogChannelType, setDialogChannelType] = useState<
    ChannelTypeEnum | undefined
  >(selectedChannelType)
  const [isMarkedAsMustRead, setIsMarkedAsMustRead] = useState(isMustRead)
  const { countries } = useCountries()

  useEffect(() => {
    if (isMustRead) {
      setIsMarkedAsMustRead(true)
    }
  }, [isMustRead])

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          setDialogCountries([])
          setDialogChannelType(undefined)
        } else {
          setDialogCountries(selectedCountries || [])
          setDialogChannelType(selectedChannelType)
        }
      }}
      title="News Channel"
      trigger={<TextLink>{selectedChannelType ? 'Edit' : 'Select'}</TextLink>}
      footer={
        <Space css={{ d: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              const patchParams: Partial<NewsArticleSettings> = {
                field_location: dialogCountries,
                channelType: dialogChannelType
              }
              if (dialogChannelType === ChannelTypeEnum.global) {
                patchParams.field_news_must_read = isMarkedAsMustRead
              }

              onUpdate(patchParams)
            }}
            disabled={
              dialogChannelType === ChannelTypeEnum.counties &&
              !dialogCountries?.length
            }
            as={DialogClose}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Space
        css={{
          maxHeight: '100%',
          pr: '$4',
          mb: '$4',
          color: '$neutral'
        }}
      >
        <Body5>To which channels do you want to publish the article?</Body5>
      </Space>

      <RadioGroup
        id="channels type"
        value={dialogChannelType}
        onValueChange={(type: ChannelTypeEnum) => {
          setDialogCountries([])
          setDialogChannelType(type)
        }}
        aria-label="channels type"
        css={{
          d: 'flex',
          flexDirection: 'column',
          gap: '$4'
        }}
      >
        <RadioItem id="global" value={ChannelTypeEnum.global} label="Global" />
        <Box
          css={{
            d: 'flex',
            justifyContent: 'space-between',
            p: '$4',
            border: '1px solid',
            borderColor: isMarkedAsMustRead
              ? '$primaryBlue'
              : '$destructiveDisabled',
            boxShadow: '0px 4px 8px 0 RGBA(0, 0, 0, 0.04)',
            borderRadius: '$3',
            cursor:
              dialogChannelType !== ChannelTypeEnum.global
                ? 'not-allowed'
                : 'pointer',
            opacity: dialogChannelType !== ChannelTypeEnum.global ? 0.5 : 1
          }}
          onClick={() =>
            dialogChannelType === ChannelTypeEnum.global &&
            setIsMarkedAsMustRead(!isMarkedAsMustRead)
          }
        >
          <Box>
            <MustReadBadge />
            <Body6 css={{ color: '$neutralLighten25', mt: 10 }}>
              {t('field_must_read_checkmark')}
            </Body6>
          </Box>
          <Checkbox
            checked={isMarkedAsMustRead}
            disabled={dialogChannelType !== ChannelTypeEnum.global}
          />
        </Box>
        <RadioItem
          id="counties"
          value={ChannelTypeEnum.counties}
          label="Countries"
        />
      </RadioGroup>

      <Space
        mt="SM"
        css={{
          d: 'grid',
          gtc: 2,
          rowGap: '$4',
          columnGap: '$12',
          width: '100%',
          '@md': { gtc: 3 }
        }}
      >
        {countries?.map((country: string) => (
          <Checkbox
            key={country}
            disabled={dialogChannelType === ChannelTypeEnum.global}
            checked={dialogCountries?.includes(country)}
            onCheckedChange={(checked) =>
              setDialogCountries((prevState) =>
                checked
                  ? [...prevState, country]
                  : prevState.filter((item) => item !== country)
              )
            }
            label={country}
          />
        ))}
      </Space>
    </Dialog>
  )
}
