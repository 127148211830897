export const AUTH_PARAM_NAME = 'sefar-auth'
export const API_DOMAIN =
  import.meta.env.VITE_API_DOMAIN ?? 'https://back.dev.my.sefar.com'
export const NOTIFICATION_DOMAIN =
  import.meta.env.VITE_NOTIFICATION_DOMAIN ?? 'notibus.sefar.com:8081'
export const CLIENT_ID =
  import.meta.env.VITE_AD_CLIENT_ID ?? '5d0cd686-4eec-4666-9135-45b9bace23e3'

export const DEV_ORIGIN = 'http://localhost:3000'

export interface Likes {
  likes: string
  dislikes: string
  clickedBy: string | null
}

const createInit = (): RequestInit => ({
  credentials: 'include',
  headers: {
    Authorization: localStorage.getItem(AUTH_PARAM_NAME)
      ? `${localStorage.getItem(AUTH_PARAM_NAME)}`
      : '',
    Accept: 'application/vnd.api+json',
    'X-CSRF-Token': localStorage.getItem(AUTH_PARAM_NAME)
      ? `${localStorage.getItem(AUTH_PARAM_NAME)}`
      : ''
  }
})

export let init: RequestInit = createInit()

window.addEventListener('auth_token_change', () => {
  // re-init on change auth token
  init = createInit()
})

export const fetcherFullRequest = (url: string, init?: RequestInit) =>
  fetch(url, init)
    .then((res) => res.json())
    .catch((error) => {
      console.error(error)
      return {}
    })

export const fetcherData = (url: string, init?: RequestInit) =>
  fetch(url, init)
    .then((res) => res.json())
    .then((res) => res.data)
    .catch((error) => {
      console.error(error)
      return error
    })
